import {altLayers} from './altLayers/altLayers'
import {existingBeds} from './existingBeds/existingBeds'
import { habitat } from './habitatLayers/habitatLayers';
import { navLayers } from './navLayers/navLayers';
import { regLayers } from './regLayers/regLayers';


//Must export as layerCompilation;
export const layerCompilation = [
	existingBeds,
	regLayers,
	navLayers,
	habitat,
	altLayers,
	// @@@@ ---- Do not change below this line - ---- @@@@
	// do not change the ID of this group  This is used by import data / server browser panel for custom layers
	{
		id:'custom',
		title:"Your Imported Data Layers",
		type:'group',
		items:[]
	}
];



