//import logo from './logo.svg';
import '@esri/calcite-components/dist/calcite/calcite.css';
import './CalciteApp.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ReactGA from 'react-ga'
import GenericHeader from './Common/GenericHeader';
import { 
  CalciteShell,

} from "@esri/calcite-components-react";
// import GenericFooter from './Common/GenericFooter';
import MapPage from './Pages/Map/MapPage';
import { useEffect } from 'react';
import { addGaScript, createWindowGTag } from './tools/googleAnalytics';
// import Disclaimer from './Pages/Map/Components/Disclaimer';
import IntroModal from './Pages/Map/Components/IntroModal';

const trackingId = process.env.REACT_APP_GA_TRACKING
// console.log(trackingId)
ReactGA.initialize(trackingId)

const basename = process.env.PUBLIC_URL;

// if( process.env.REACT_APP_HOMEPAGE_FOLDER ) {
//   basename.basename = process.env.REACT_APP_HOMEPAGE_FOLDER;
// }else{
//   basename.basename='/';
// }

const CalciteApp = () =>{
addGaScript()
createWindowGTag()

window.gtag('config', process.env.REACT_APP_GA_TRACKING, {
  path_title: window.location.pathname,
  path_page: window.location.pathname
})

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
      <CalciteShell>
        <BrowserRouter {...basename}>
          <IntroModal/>
          <Routes>
            <Route path="/" element={<MapPage />} />
            <Route path="map/:shareMapKey" element={<MapPage />} />
          </Routes>
          <GenericHeader />
          {/* <GenericFooter /> */}
        </BrowserRouter>
		  </CalciteShell>
  );
}

export default CalciteApp;
