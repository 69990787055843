import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Mullica_River_Title_50_Line/FeatureServer";

const portalId = "4b18292aa50d40ecac5d1f23fa3fdc8f";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Mullica River Title 50 Line ",
  popupTemplate: {
    title: "{name}",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
    ],
  },
  layerDescription: "Title 50:1-23 “Shellfish” describes a line that delineates lands in tidal waters of the Mullica River in which no additional leases will be granted. No new leases shall be granted under the waters of the Mullica River above a line extending in a westerly direction from the south end of Deep Point. Existing leases may be granted “heretofore” in this area of the Mullica. In summary, leases that were previously granted and were subsequently vacated, could be granted again.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const mullicaRiver = {layerId: 'mullicaRiver', type: 'layer', item: layer, meta: layer}

