
import { uscgNav } from "./portalNavAids";
import { njdotChan} from "./portalNjdotChan";
// import { vesselTracks} from "./coastNoaaTracks";
// import {noaaChart} from './portalNoaaChart' // this layer fails because of CORS 

export const navLayers ={
  id: 'navigation',
  title: "Navigation Layers",
  type: 'group',
  items: [
    uscgNav,
    njdotChan,
    // vesselTracks, // rbabb requested that we turn off this layer 7/25/2022
    // noaaChart, // this layer fails because of CORS 
  ]
};

