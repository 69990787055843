import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url =  "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/NJDOT_Waterway_Channels/FeatureServer";

const portalId = "9cf28429f382490a98e5cb3fc8176b8e";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "NJDOT Channels",
  popupTemplate: {
    title: "{Name}",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
    ],
  },
  layerDescription: "no description provided by layer author",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const njdotChan = {layerId: 'njdotChan', type: 'layer', item: layer, meta: layer}
  