import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Imperiled_Species_for_Aquaculture_Planning/FeatureServer";

const portalId = "8cbc5cc1b78d4e6db03738d807f44439";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Imperiled Species for Aquaculture Planning",
  popupTemplate: {
    title: "Imperiled Species Concern",
    content: [
      {
        type: "text",
        text: `Level of Concern: {CONCERN_LEVEL}`,
      },
      {
        type: "text",
        text: `Period of Concern: {CONCERN_PERIOD}`,
      },],
  },
  layerDescription: <div><p>The data will help the authorized programs to avoid direct and indirect impacts to extant, rare animal species in the course of their activities. These data are to be accessed and used solely to assist with official NJDEP or US Fish and Wildlife Service business, such as for planning and environmental review, and not for personal use.</p>
    <h3>Aquaculture impact categories: </h3>
    <p>
      Low:  Based upon current wildlife species occurrence and habitat use data, shellfish aquaculture activities in these areas are expected to have a low probability of affecting wildlife/wildlife habitats.  The occurrence of adverse effects on wildlife could result in increased compliance requirements should permitting be required, and/or could result in “take” of endangered, threatened, or special concern wildlife that is prohibited under the Endangered and Nongame Species Conservation Act (N.J.S.A. 23:2A).   </p><p>

      Medium:  Based upon current wildlife species occurrence and habitat use data, shellfish aquaculture activities in these areas are expected to have a medium probability of affecting wildlife/wildlife habitats.  The chances of adversely affecting wildlife may depend upon the types of equipment utilized and/or the degree or extent to which the activities involve human disturbance/tending.  The occurrence of adverse effects on wildlife could result in increased compliance requirements should permitting be required, and/or could result in “take” of endangered, threatened, or special concern wildlife that is prohibited under the Endangered and Nongame Species Conservation Act (N.J.S.A. 23:2A).    </p><p>
      High:  Based upon current wildlife species occurrence and habitat use data, shellfish aquaculture activities in these areas are expected to have a high probability of affecting wildlife/wildlife habitats.  The chances of adversely affecting wildlife may depend upon the types of equipment utilized and/or the degree or extent to which the activities involve human disturbance/tending.  The occurrence of adverse effects on wildlife could result in increased compliance requirements should permitting be required, and/or could result in “take” of endangered, threatened, or special concern wildlife that is prohibited under the Endangered and Nongame Species Conservation Act (N.J.S.A. 23:2A).    </p><p>

      Period of Concern:  The timeframe within which the indicated “Level of Concern” applies to the area specified.  The “Period of Concern” reflects the timeframe within which the wildlife species considered by the mapping are expected to occupy the referenced habitats.
    </p>  </div>,
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
  disclaimer: <div>
    <p>The “Imperiled Species” data layer was developed solely as a planning tool, intended to depict areas where activities specifically associated with shellfish aquaculture could potentially affect imperiled wildlife species (i.e., species classified in New Jersey as “endangered,” “threatened” or “species of special concern.”). Where such potential is identified, the planning tool characterizes that potential as being “low,” “medium,” or “high.”  Note that the potential to affect wildlife does not imply that any such effect would be “adverse.” The planning tool identifies areas wherein shellfish aquaculture activities may undergo greater scrutiny regarding the potential to impact wildlife/wildlife habitats. This could apply to requirements for any required permits or approvals, and/or could affect standards established by state or federal law or regulation. </p>

    <p> By agreeing to use this data layer, you are acknowledging that the “Imperiled Species” data layer:</p>
      <ul>
        <li> does NOT represent comprehensive habitat occupancy mapping for all imperiled species in New Jersey;  </li>

        <li> does NOT map the full extent of habitats utilized by the species that have been factored into the data layer (in some cases, only habitats serving the most critical life history needs of the species are identified); </li>

        <li> ONLY addresses the potential for effects associated with shellfish aquaculture activities, and only anticipates effects associated with the shellfish aquaculture gear, practices and activities commonly in use at the time of the tool's release;  </li>

        <li> is based solely upon wildlife species occurrence data available to NJ DEP Fish and Wildlife (NJDEPFW) at the time of tool's release.</li>
      </ul>
    <p>
      The “Imperiled Species” data layer does not replace the use of the Landscape Project Maps, whether as required pursuant to N.J.A.C. 7:7-9.36 or in any other situation or context where comprehensive habitat occupancy mapping of imperiled species is required or desired.  Shellfish aquaculture activities are subject to complete review through various State and Federal review procedures that shall remain unaffected and unconstrained by the availability of this planning tool.  This tool is NOT referenced or considered when making any regulatory or permitting decision. </p>
    <p>
      These data are public information provided by NJDEPFW. Distribution of these data to other users by anyone other than NJDEPFW or the New Jersey Department of Environmental Protection is not authorized.   </p>
    <p>

      Providing public access to wildlife habitat data is NOT a substitute for consultation with NJDEPFW staff or other state and federal agencies regarding actual permit applications or environmental reviews.</p>
  </div>
});

export const imperiled = { layerId: 'imperiled', type: 'layer', item: layer, meta: layer }
