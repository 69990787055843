import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Dry_Bay_Biological_Exemption_Line/FeatureServer";

const portalId = "c6495791e6344782a4d3847413cfb0d9";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Dry Bay Biological Exemption Line ",
  popupTemplate: {
    title: "{name}",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
    ],
  },
  layerDescription: "Regulatory layer pertaining to shellfish leases: no new leases east of line (there were a few granted after line was set up as they were pending approval after line was drawn) and no biological investigations west of line for new leases.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const dryBay = {layerId: 'dryBay', type: 'layer', item: layer, meta: layer}
