import React, { useRef, 
	useEffect,
	//useState 
} from "react";
import { connect } from "react-redux";

import Print from "@arcgis/core/widgets/Print";

import PrintMapBlock from './PrintMapBlock'
import TemporaryMapBlock from "./TemporaryMapBlock";
// import SaveMapBlock from "./SaveMapBlock";

const SaveSharePrintPanel = ({isMapLoading, arcgisMap}) => {

	const printContainer = useRef(null);

	useEffect(() => {
		//console.log(arcgisMap, isMapLoading);
	  	if (printContainer.current && arcgisMap.map) {
			arcgisMap.when( () => {
				new Print({
					view: arcgisMap,
					container: printContainer.current,
					allowedLayouts: [
					  "letter-ansi-a-landscape",
					  "letter-ansi-a-portrait",
					  "tabloid-ansi-b-landscape",
					  "tabloid-ansi-b-portrait",
					  // "a3-landscape",
					  // "a3-portrait",
					  // "a4-landscape",
					  // "a4-portrait",
					],
				  }
				  );
			});

		}
	}, [arcgisMap]);


	return (
		<>
			<TemporaryMapBlock  arcgisMap={arcgisMap} />
			{/* <SaveMapBlock  arcgisMap={arcgisMap} /> */}
			<PrintMapBlock arcgisMap={arcgisMap} />
		</>
	);
}

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveSharePrintPanel);
