import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://coast.noaa.gov/arcgis/rest/services/OceanReports/SubmarineCableAreas/MapServer/0";

export const layer = new FeatureLayer({
  url,
  title: "Submarine Cable Areas",
  popupTemplate: {
    title: "Submarine Cable Area - {squareKilometer} km",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
    ],
  },
  visible: true,
  layerDescription: "Submarine Cable Areas",
  mapService: url,
  authoritiveSource: `https://www.ncei.noaa.gov/maps-and-geospatial-products`,
});

export const cableAreas = {layerId: 'cableAreas', type: 'layer', item: layer, meta: layer}
