import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://mapsdep.nj.gov/arcgis/rest/services/Features/Environmental_admin/MapServer/4";

const portalId = "0fcff847f0d249e48ab969fe9e8be1a4";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Shellfish Report Areas",
  popupTemplate: {
    title: "Shellfish Report Area: {REPORT_GROUP}",
    content: [
      {
        type: "text",
        text: `Reporting Group: {REPORT_GROUP}`,
      },
      {
        type: "text",
        text: `Reporting Area: {REPORT_AREA}`,
      },
      {
        type: "text",
        text: `Reporting Area Name: {REPORT_AREA_LABEL}`,
      },
      {
        type: "text",
        text: `Reporting Area Website: <a href={REPORT_AREA_URL}>More Info</a>`,
      },
      {
        type: "text",
        text: `Sanitary Survey: <a href={SANITARY_SURVEY}>More Info</a>`,
      },
      {
        type: "text",
        text: `Reappraisal: <a href={REAPPRAISAL}>More Info</a>`,
      },
    ],
  },
  layerDescription: "This data spatially represents report boundaries used to quantify and summarize water quality data collected within NJ coastal waters for the National Shellfish Sanitation Program (NSSP). The primary purpose of each reporting area is to comply with the guidelines of the NSSP that are established by the Interstate Shellfish Sanitation Conference (ISSC). Reports generated under this program form the basis for classifying shellfish waters for the purpose of harvesting shellfish for human consumption. As such, they provide a critical link in protecting human health. All water quality data along with Sanitary Surveys conducted within each report area boundary are reviewed and analyzed to validate and determine if NJ's current shellfish classification statuses are accurate or in need of modification. This dataset will provide a link to the NSSP reports that are generated by the Bureau of Marine Water Monitoring.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const reportArea = {layerId: 'reportArea', type: 'layer', item: layer, meta: layer}
