
  // inpired by https://blog.cetindere.de/integrate-google-analytics-into-your-react-web-app/
import ReactGA from 'react-ga'

export const useAnalyticsEventTracker = (category="Blog Category") => {
	const eventTracker = (action = 'test action', label = 'test label') => {
		ReactGA.event({ category, action, label});
	}
	return eventTracker;
}
  
  export const addGaScript = () => {
    const gaScript = document.createElement('script')
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING}`
  document.body.append(gaScript)
  
  }

  export const createWindowGTag = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments)}
      window.gtag = gtag;
      gtag('js', new Date())

      gtag('config', process.env.REACT_APP_GA_TRACKING)
  }
  

  <script async src="https://www.googletagmanager.com/gtag/js?id=G-JNW54JV42Q"></script>