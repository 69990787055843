import React,{ useState} from "react";
import { CalciteModal, CalciteButton } from "@esri/calcite-components-react";
import njfwLogo from '../../../Common/images/njfw.png';
import njdepLogo from '../../../Common/images/njdeplogo.png';
import rutgersLogo from '../../../Common/images/RU_SIG_NJAES_PMS186_100K.svg';
import pewLogo from '../../../Common/images/pewLogo.png';
import seaGrantLogo from '../../../Common/images/SeaGrantLogo.png';

const Logo = {
    verticalAlign:'middle', 
    width: '100px', 
    marginRight: '1.5rem',
	marginBottom: '1rem',
}
const NLogo = {
    verticalAlign:'middle', 
    width: '100px', 
    marginRight: '1rem',
}

const RLogo = {
	verticalAlign:'middle',
	width: '160px', 
    marginLeft: '1rem',
	paddingTop: '2rem',
}

const LogoHolder = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexWrap: 'wrap',

}

/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */

const LogoItem = ({url, imgSrc, imgAlt, styling}) => (
	<a href={url} target="_blank" rel='noreferrer'>
		<img src={imgSrc} style={styling} alt={imgAlt} />
	</a>
)

const IntroModal = () => {

    const currentYear = new Date().getFullYear() // for copywrite
	const localStorageKey = 'intro-expires';
    let currentKey = window.localStorage.getItem(localStorageKey) ?? Date.now() // is either null or a Date.now() number

	// if current key is less than now, intro has expired, show again, otherwise don't show it
    const initialState = currentKey > Date.now() ? false : true
	console.log(initialState)
    
	const [showModal, setShowModal] = useState(initialState);
	// const [showModal, setShowModal] = useState(true); // for developing the introModal, shows modal on refresh instead of waiting an hour.

	const closeModal = () => {
		let currTime = Date.now();
		let increaseTime = (3600 * 1000); // 1 hour:  (3600 * 1000)

		window.localStorage.setItem(localStorageKey, currTime + increaseTime );
		setShowModal(false);
		// return true;
	}

	return (
		<CalciteModal active={showModal || null} onCalciteModalClose={closeModal}>
			<div slot="header" >
				<strong>New Jersey Aquaculture Siting Information Tool</strong> 
			</div>
			<div slot="content">

				<h3 className="small-margin-bottom no-margin-top">Application Help &amp; Information</h3>
				<p>The NJ Aquaculture Siting Information Tool is for informational use only. It is an interactive map viewer intended to assist industry and resource managers in the appropriate siting of shellfish aquaculture leases using the best available data.</p>
				<div>
					<p>You can:</p>
					<ul>
						<li>Find a specific site location on the map using the address or interactively by zooming and panning.</li>
						<li>Display various geospatial information related to that location by turning layers on or off.</li>
						<li>Click on a location and an information window will pop-up with relevant attributes pertaining to the location. </li>
					</ul>	

					<p>
				To learn more about commercial shellfish aquaculture in NJ, go to <a href='https://dep.nj.gov/aquaculture/' target='_blank' rel='noreferrer'>NJDEP Aquaculture</a>. This site provides shellfish growers with resources on a variety of topics related to commercial shellfish aquaculture in New Jersey all in one place. This includes, but is not limited to: leasing, permitting, and safe harvest and handling practices. Each aquaculture operation in New Jersey is unique and will require different considerations, but the information provided in this website is meant to serve as a resource for general information needed to grow shellfish in the State.
				</p>				
				</div>

				

				<h3 className="small-margin-bottom no-margin-top">Disclaimer</h3>
				<p>This tool does not replace any legal and permitting reviews or procedures. The information provided is neither binding nor final. Prospective aquaculturists are encouraged to use this tool in coordination with the Department of Environmental Protection and the New Jersey Shellfisheries Councils. The DEP's Shellfisheries Bureau can be reached at (609) 748-2040.</p>

				<p><em>Support for this tool has been/is graciously provided by the following project partners:</em></p>  
				

				<div style={LogoHolder}>
					<LogoItem url='https://njseagrant.org/' imgSrc={seaGrantLogo} imgAlt='New Jersey Sea Grant Consortium' styling={Logo} />
					<LogoItem url='https://www.pewtrusts.org/' imgSrc={pewLogo} imgAlt='The Pew Charitable Trusts' styling={NLogo} />
					<LogoItem url='https://njaes.rutgers.edu/' imgSrc={rutgersLogo} imgAlt='Rutgers University New Jersey Agriculture Experiment Station' styling={RLogo} />
					<LogoItem url='https://dep.nj.gov/' imgSrc={njdepLogo} imgAlt='New Jersey Department of Environmental Protection' styling={NLogo} />
					<LogoItem url='https://dep.nj.gov/njfw/' imgSrc={njfwLogo} imgAlt='Fish and Wildlife Agency' styling={NLogo} />
				</div>	

				<p><em>Rutgers is an equal access/equal opportunity institution.  Individuals with disabilities are encouraged to direct suggestions, comments, or complaints concerning any accessibility issues with Rutgers' websites to <a href="mailto:accessibility@rutgers.edu">accessibility@rutgers.edu</a> or complete the university's <a href="https://rutgers.ca1.qualtrics.com/jfe/form/SV_57iH6Rfeocz51z0">Accessibility Barrier</a> form. &copy;{currentYear}, Rutgers, The State University of New Jersey.  All rights reserved.  Contact RU-info at 848-445-info (4636). Rutgers University web privacy policy can be found at: <a href="https://newbrunswick.rutgers.edu/privacy-policy">https://newbrunswick.rutgers.edu/privacy-policy</a>.
				</em></p>		

			</div>
			<CalciteButton slot="primary" color="neutral" icon="chevron-left" width="full" onClick={closeModal}>Close Window</CalciteButton>
		</CalciteModal>
	)
};

export default IntroModal;
