const formatDesc = (desc) => {
    let description
    // console.log('formatDesc item', typeof item)
    // if (item === '') {
    if (desc?.includes('</')) {
        // console.log('description includes html')
        description = <div dangerouslySetInnerHTML={{ __html: desc }}></div>
    } else {
        //   console.log('description does not include html')
        description = desc
    }
    // }
    // console.log('description1', description)
    return description
}

export default formatDesc