import React, {	useRef,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {removeLayer} from '../../../../Flux/thunks';
import { CalciteSortableList} from "@esri/calcite-components-react";
import LayerListBlock from "../../Components/LayerListBlock";

const LayerControlPanel = () => {
	const dispatch = useDispatch()

	const layerControl = useRef(null);

	const {arcgisMap, layerList} = useSelector(state => state)
	
	const fullReordering = (myNodeList) => {

		for (let index = myNodeList.length - 1; index >= 0; index--) {
			const layerId = myNodeList[index].layerId;
			const findLayer = layerList.find((layerItem) => {
				return layerItem.id === layerId;
			});

			if (findLayer) {
				arcgisMap.map.reorder(findLayer, myNodeList.length - index);
			}
		}
	}
	

	const toggleLayer = (layerItem, isVisible = "on") => {
		// console.log(`[toggleLayer] The layer "${layerItem.title}" `, isVisible);
		layerItem.visible = (isVisible === 'on');
	}
	const removeLayerFromMap = (layer) =>  dispatch(removeLayer(layer)) 


	useEffect(() => {

		if (layerControl.current && !layerControl.current.children.length && arcgisMap.map) {

		}

	}, [arcgisMap]);

	const reversedLayerList = layerList.map((item => item)).reverse();
	const blockElems = reversedLayerList.map(layerItem => (
		<LayerListBlock key={layerItem.id} layerItem={layerItem} handleVisibility={toggleLayer} handleRemove={removeLayerFromMap}/>
	))

	return (
		<div className="content-area">
			{layerList.length > 0 ? (
				<CalciteSortableList onCalciteListOrderChange={evt => {
					fullReordering(evt.target.childNodes);
				}}>
					{blockElems}
				</CalciteSortableList>
				) : <div>No Layers Loaded On Map Yet</div>}

		</div>
	);
}

export default LayerControlPanel
