import {	LOAD_MAP_IN_PROGRESS,LOAD_MAP_FAILURE,LOAD_MAP_SUCCESS, SET_MODAL_CONTENT, SHOW_MODAL, AGREE, DISAGREE} from './actions'

	const initialState = {
		isLoading: false,
		data: {},
	}

	const intialModal = {
		isVisible: false,
		data: {}
	}


	export const mapview = (state = initialState, action ) =>{
		const {type, payload} = action;
	
		switch( type ) {
			case LOAD_MAP_SUCCESS: {
				let {view} = payload
				return { ...state, isLoading: false, data: view };
			}
			case LOAD_MAP_IN_PROGRESS: {
				return { ...state, isLoading: true };
			}
			case LOAD_MAP_FAILURE: {
				return { ...state, isLoading: false };
			}
			default: {
				return state;
			}
		}
	}

	export const modal = (state = intialModal, action) => {
		const {type, payload} = action;

		switch (type) {
			case SHOW_MODAL: {
				let {value} = payload
				return {...state, isVisible: value}
			}
			case SET_MODAL_CONTENT: {
				let {content} = payload
				return { ...state, data: content }
			}
			default: {
				return state;
			}
		}
	}

	export const imperiledAgreement = (state = false, action) => {
		const {type} = action;
		switch(type) {
			case AGREE: {
				
				return true
			}
			case DISAGREE: {
				return false
			}
			default: {
				return state;
			}
		}
	}
	

