import React, { 
	// useState
 } from 'react';
import { CalcitePanel } from "@esri/calcite-components-react";
// import HamburgerAction from './hamburgerMenu/HamburgerAction';
// import HamburgerPanel from './hamburgerMenu/HamburgerPanel';

const GenericHeader = () => {
	// const [collapsed, setCollapsed] = useState(true);
	
	
    // const handleCollapse = () => {
    //     setCollapsed(!collapsed)
    // }

	return (
		<>
			<CalcitePanel slot="header" heading={process.env.REACT_APP_HEADER_FULL_TITLE || 'Please update the .env file with a full title project name'}  >
				{/* If there are several pages and you need a navigation menu */}
			{/* <HamburgerAction isCollapsed={collapsed} handleCollapse={handleCollapse}/> */}


			{/* if you want to use a logo image, it would go here */}
			{/* <MyLogoImage slot='header-actions-start'/> */}
			</CalcitePanel> 

			{/* If you used Hamburger ACtion you also need hamburger panel */}
			{/* <HamburgerPanel isCollapsed={collapsed}/> */}
			
		</> 
	);
}

export default GenericHeader;