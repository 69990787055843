import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Bottom_Hardness_Data/FeatureServer/1";

const portalId = "ae9a3a0803284482839f812714c10c3e";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  layerId: 1,
  visible: true,
  title: "Hard Bottom Composition",
  popupEnabled: true,
  layerDescription: "Point data were also collected using the Humminbird Helix 10 Chirp SI GPS after using the sidescan sonar to identify hard bottom.  A small sampling dredge or oyster tongs were used to determine the presence of oysters, other benthic organisms, and cultch on the hard bottom areas.   Data, consisting of what was collected, were recorded with the corresponding gps co-ordinates.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});



export const hardBottom = {layerId: 'hardBottom', type: 'layer', item: layer, meta: layer}
