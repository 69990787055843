import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://mapsdep.nj.gov/arcgis/rest/services/Features/Environmental_admin/MapServer/41";

let portalId = "3f1821fe04f544749aa8b943ebccb627";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  layerId: 41,
  title: "Shellfish Lease Exclusion Areas in New Jersey",
  popupTemplate: {
    title: "Shellfish Lease Exclusion Areas in New Jersey",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a> <br/><b>Region:</b> {REGION}<br/>    <b>Name:</b> {NAME}<br/>    <b>Description:</b> {DESCRIPTION}`
      }
    ],
  },
  visible: true,
  layerDescription: `This data is to delineate the following: areas as defined under Title 50 in which leases shall not be granted or where no new leases will be granted; lease restriction areas defined by Council policy and hard clam productivity; exclusion areas defined in the Leasing of Atlantic Coast Bottom for Aquaculture; or other regulatory or policy areas where leases are exclusive, limited, not allowed or where no new leases are permitted.
  `,
  mapService: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
  authoritiveSource: `https://www.nj.gov/dep/gis/`
});

export const shellfishExclusion = {layerId: 'shellfishExclusion', type: 'layer', item: layer, meta: layer}



