import { CalcitePanel } from "@esri/calcite-components-react";
import React, { useRef, 
	useEffect 
} from "react";

import './Map.css';

const Map = ({arcgisMap, loading}) => {

	const mapDiv = useRef(null);
	useEffect(() => {
	  	if (mapDiv.current) {
			arcgisMap.container = mapDiv.current;
			

		}
	}, [arcgisMap]);
	
	return (<CalcitePanel loading={loading}><div className="mapDiv" ref={mapDiv}></div></CalcitePanel>);
}

export default Map;