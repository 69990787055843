import formatDesc from "./formatDesc"

const getMapSvcOps = async (layer) => {

    // console.log('layer', layer)

    let response = await layer.load()
    let { sourceJSON, type } = response
    // console.log('sourceJSON', sourceJSON)
    let { description, serviceDescription, name, copyrightText } = sourceJSON
    let  desc
    let output = {
        title: name,
        description: description,
        authoritiveSource: copyrightText,
    }

    if (type === 'map-image') {
        console.log('is a map image')
        let subResponse = await response.sublayers.items[0].load()
        let { sourceJSON: subJSON } = subResponse
        let { description: subDescription, name, copyrightText } = subJSON
        // console.log('subdescription', subDescription)
        desc = formatDesc(subDescription)
        output = {
            title: name,
            description: desc,
            authoritiveSource: copyrightText
        }
    }
    else if (description === '') {
        console.log('no description')
        desc = formatDesc(serviceDescription)
        output.description = desc
    } else {
        console.log('has description')
        desc = formatDesc(description)
        output.description = desc
        
    }
    // console.log('description', description)
    return output
}

export default getMapSvcOps