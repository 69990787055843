import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Hand_Tonging_Areas/FeatureServer";

const portalId = "bf65dc6a767d442885c9cf1a0072b5f5";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  title: "Hand Tonging Areas",
  popupTemplate: {
    title: "{Name}",
  },
  visible: true,
  layerDescription: "Per Title 50, Section 3:14, various oyster beds located in areas, creeks, rivers, and coves located along the New Jersey side of the Delaware Bay coast are designated for harvest with the use of hand tongs only. Please refer to Title 50, Section 3:15 and 3:15.1 for additional information on size limits and closed season exceptions. Please note that water quality classification set by the Bureau of Marine Water Monitoring supersedes these rules.Please refer to Title 50 Section 3:16.4 for a description of authorized shellfish harvest equipment in Area 1 - Tongers Area.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const delTongers = {layerId: 'delTongers', type: 'layer', item: layer, meta: layer}
 