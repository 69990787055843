import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Mullica_Seed_Beds/FeatureServer";

const portalId = "43cb6331c69a499299f4142857286ad4";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  title: "Mullica Seed Beds ",
  popupTemplate: {
    title: "{NAME}",
  },
  visible: true,
  layerDescription: "The Mullica Seed beds consist of two beds, French’s Point Bed and Moss Point Bed. No leases are allowed in these beds. Existing Mullica leases only extend from shoreline out to 16 feet at mean low water. No new leases are allowed, but existing leases can be re leased if vacated, as per Title 50. No persons shall catch oysters on these beds as per the Atlantic Coast Harvest Season regulations at NJAC 7:25-19. These beds are monitored by the Bureau of Shellfisheries and used periodically to enhances natural for the purpose of harvest on natural oyster beds during approved seasons.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const mullicaSeed = {layerId: 'mullicaSeed', type: 'layer', item: layer, meta: layer}
