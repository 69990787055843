// import { atlanticLeases } from "./portalAtlanticLeases";
// import { atlRelay } from "./portalAtlanticRelay";
import { delAquaDev } from "./portalDelAquaDev";
import { delAreas } from "./portalDelAreas";
// import { delOysterBeds } from "./portalDelOysterBeds";
import { delOysterBounds } from "./portalDelOysterBound";
// import {delShellLeases} from "./portalDelShellLease";
import { delTongers} from "./portalDelTongers";
// import { greatBay } from "./portalGreatBay";
// import { kings } from "./portalKings";
import { mullicaSeed } from "./portalMullicaSeed";
import { shellfishLeases } from "./portalShellfishLeases";
import {shellfishSections} from './portalShellfishSections'
// import { tidelands } from "./portalTidelands";

export const existingBeds = {
  id: 'existing-beds',
  title: "Existing Beds",
  type: 'group',
  items: [
    // atlanticLeases,
    // atlRelay,
    delAquaDev,
    delAreas,
    // delOysterBeds,
    delOysterBounds,
    // delShellLeases,
    delTongers,
    // greatBay,
    // kings,
    mullicaSeed,
    shellfishLeases,
    shellfishSections,
    // tidelands,
  ],
}



