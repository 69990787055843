import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

let url = "https://mapsdep.nj.gov/arcgis/rest/services/Features/Environmental_admin/MapServer/7";

let portalId = "354ca2dc99084cd8a4ce4a2e19b819b1";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  title: "NJ Public Access - Zoom to View",
  copyright: "pubAccess",
  popupTemplate: {
    title: "{MUN_LABEL}",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "PARKING",
            label: "Parking",
          },
          {
            fieldName: "STREET",
            label: "Street",
          },
          {
            fieldName: "CROSS_ST",
            label: "Cross Street",
          },
          {
            fieldName: "BADGE",
            label: "Badge Required",
          },
          {
            fieldName: "SWIMMING",
            label: "Swimming Allowed",
          },
          {
            fieldName: "FISHING",
            label: "Fishing Allowed",
          },
          {
            fieldName: "SURFING",
            label: "Surfing Allowed",
          },
          {
            fieldName: "PLAYGRD",
            label: "Playground Present",
          },
          {
            fieldName: "PARK",
            label: "Park Present",
          },
          {
            fieldName: "PIER",
            label: "Pier Present",
          },
          {
            fieldName: "BOATLNCH",
            label: "Boatlaunch Available",
          },
          {
            fieldName: "MARINA",
            label: "Marina Available",
          },
          {
            fieldName: "FOOD_DRINK",
            label: "Food and Drink Available",
          },
          {
            fieldName: "RESTRM",
            label: "Restrooms Available",
          },
          {
            fieldName: "H_C",
            label: "Handicap Accessible",
          },
          {
            fieldName: "SHORELINE",
            label: "Shoreline",
          },
          {
            fieldName: "ACCESS_TYP",
            label: "Access Type",
          },
          {
            fieldName: "COUNTY_LAB",
            label: "County",
          },
          {
            fieldName: "MUN_LABEL",
            label: "Municipality",
          },
          {
            fieldName: "COMMENTS",
            label: "Comments",
          },
        ],
      },
    ],
  },
  layerDescription:  "The public's right to access tidal waters and their shorelines is a concept that existed prior to the inception of the State of New Jersey and continues to this day. Public access is a right expressed in the Public Trust Doctrine, in the many court cases that have interpreted and implemented its strictures, and implicitly in the many legislative acts and regulatory provisions that have sought to protect and enhance access. The Department of Environmental Protection has long seen protecting this right as a vital part of our mission, and we require public access to the shore and tidal waterways under the Coastal Zone Management rules. Staff works with municipal governments to develop voluntary Municipal Public Access Plans consistent with the Coastal Permit Program (N.J.A.C. 7:7) and Coastal Zone Management rules (N.J.A.C. 7:7E) in an effort develop Municipal Public Access Plans to enhance public access to tidal waters in a comprehensive manner. These plans consist of an inventory of existing public access amenities within a municipality, along with a needs assessment, and a list of planned enhancements or expansions of public access facilities in accordance with State criteria. This layer represents an inventory of public access points that has been collected to date. As additional public access plans and inventories are developed this layer will be updated.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const pubAccess = {layerId: 'pubAccess', type: 'layer', item: layer, meta: layer}
