import TileLayer from "@arcgis/core/layers/TileLayer";

let url = 'https://maps2.coast.noaa.gov/arcgis/rest/services/dc_slr/';

let desc = "This dataset was created as part of the National Oceanic and Atmospheric Administration Office for Coastal Management's efforts to create an online mapping viewer depicting potential sea level rise and its associated impacts on the nation's coastal areas. The purpose of the mapping viewer is to provide coastal managers and scientists with a preliminary look at sea level rise and coastal flooding impacts. The viewer is a screening-level tool that uses nationally consistent data sets and analyses. Data and maps provided can be used at several scales to help gauge trends and prioritize actions for different scenarios. The purpose of this dataset is to show potential sea level rise inundation of the given amount of feet above current Mean Higher High Water (MHHW) for the area. Tiles have been cached down to Level ID 15 (1:18,055). This dataset illustrates the scale of potential flooding, not the exact location, and does not account for erosion, subsidence, or future construction. Inundation is shown as it would appear during the highest high tides (excludes wind driven tides) with the sea level rise amount. The dataset should be used only as a screening-level tool for management decisions. As with all remotely sensed data, all features should be verified with a site visit. The dataset is provided 'as is', without warranty to its performance, merchantable state, or fitness for any particular purpose. The entire risk associated with the results and performance of this dataset is assumed by the user. This dataset should be used strictly as a planning reference and not for navigation, permitting, or other legal purposes. For more information visit the Sea Level Rise Impacts Viewer (http://coast.noaa.gov/slr)."

let slr00 = {
  layerId: 'slr-0ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_0ft/MapServer`,
  title: "Sea Level Rise 0ft",  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 0ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr01 = {
  layerId: 'slr-1ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_1ft/MapServer`,
  title: "Sea Level Rise 1ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 1ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr02 = {
  layerId: 'slr-2ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_2ft/MapServer`,
  title: "Sea Level Rise 2ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 2ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr03 = {
  layerId: 'slr-3ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_3ft/MapServer`,
  title: "Sea Level Rise 3ft",
  visible: true,
}),
meta: {
  title: "Sea Level Rise 3ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr04 = {
  layerId: 'slr-4ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_4ft/MapServer`,
  title: "Sea Level Rise 4ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 4ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr05 = {
  layerId: 'slr-5ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_5ft/MapServer`,
  title: "Sea Level Rise 5ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 5ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr06 = {
  layerId: 'slr-6ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_6ft/MapServer`,
  title: "Sea Level Rise 6ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 6ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr07 = {
  layerId: 'slr-7ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_7ft/MapServer`,
  title: "Sea Level Rise 7ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 7ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr08 = {
  layerId: 'slr-8ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_8ft/MapServer`,
  title: "Sea Level Rise 8ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 8ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr09 = {
  layerId: 'slr-9ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_9ft/MapServer`,
  title: "Sea Level Rise 9ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 9ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};

let slr10 = {
  layerId: 'slr-10ft',
  type: 'layer',
  item: new TileLayer({
  url: `${url}slr_10ft/MapServer`,
  title: "Sea Level Rise 10ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
  visible: true,
}),
meta: {
  title: "Sea Level Rise 10ft",
  
  layerDescription: desc, 
  mapService: url,
  authoritiveSource: `https://coast.noaa.gov/slr/`, 
}
};


export const slrGroup = {
  id: 'slr',
  title: "Sea Level Rise",
  type: 'group',
  items: [
    slr00,
    slr01,
    slr02,
    slr03,
    slr04,
    slr05,
    slr06,
    slr07,
    slr08,
    slr09,
    slr10,
  ],
};

