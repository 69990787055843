import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Bottom_Hardness_Data/FeatureServer/0";

const portalId = "ae9a3a0803284482839f812714c10c3e";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  layerId: 0,
  visible: true,
  title: "Bottom Hardness",
  popupEnabled: true,
  // popupTemplate: {
  //   title: "{Site_name}",
  //   content: [
  //     {
  //       type: "media",
  //       mediaInfos: [{
  //         type: 'image',
  //         value: {}
  //       }]
  //     },
      
  //   ],
  // },
  layerDescription: "Sidescan sonar maps of bottom hardness and depth (bathymetry) were created using a Humminbird Helix 10 Chirp SI GPS unit with the Auto Chart feature which record data to the Auto Chart Zero Lines sd card. The recording feature of the Auto Chart program is turned on and set to record the bottom hardness of the area the boat is covering. Depth is recorded automatically.  The Zero lines card is then put into the Auto Chart Pro Software on a PC and converted into maps.  Humminbird data is encrypted and not available for export into other formats but the maps produced by the Auto Chart Pro Software are georeferenced allowing them to be properly placed into a GIS.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const bottom = {layerId: 'bottom', type: 'layer', item: layer, meta: layer}
