import { savGroup } from "./savGroup";
import { assocGroup } from "./assocGroup";
import { clamGroup } from "./clamGroup";

export const inventoryGroup = {
  id: 'inventory',
  title: "Estuarine Inventory of New Jersey",
  type: 'group',
  items: [
    clamGroup,
    savGroup,
    assocGroup
  ],
};