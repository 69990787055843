import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://coast.noaa.gov/arcgis/rest/services/MarineCadastre/PhysicalOceanographicAndMarineHabitat/MapServer/16";

export const layer = new FeatureLayer({
  url,
  title: "USGS Sediment Texture",
  popupTemplate: {
    title: "{CLASSIFICA}",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
      {
        type: "fields",
        fieldInfos: [
          { fieldName: "DEPTH_M", label: "Depth in meters" },
          { fieldName: "GRAVEL_PCT", label: "Percent Gravel" },
          { fieldName: "SAND_PCT", label: "Percent Sand" },
          { fieldName: "SILT_PCT", label: "Percent Silt" },
          { fieldName: "CLAY_PCT", label: "Percent Clay" },
        ],
      },
    ],
  },
  visible: true,
  layerDescription: "USGS Sediment Texture",
  mapService: url,
  authoritiveSource: `https://marinecadastre.gov/`,
});

export const sediment = {layerId: 'sediment', type: 'layer', item: layer, meta: layer}
