//MAP LOADING
export const LOAD_MAP_IN_PROGRESS = 'LOAD_MAP_IN_PROGRESS';
export const loadMapInProgress = () => ({
	type: LOAD_MAP_IN_PROGRESS
}); 

export const LOAD_MAP_SUCCESS = 'LOAD_MAP_SUCCESS';
export const loadMapSuccess = (view) => {
	//console.log('Debug [loadMapSuccess]', view);
	return {
	type: LOAD_MAP_SUCCESS,
	payload:{view}
}}; 
export const LOAD_MAP_FAILURE = 'LOAD_MAP_FAILURE';
export const loadMapFailure = () => ({
	type: LOAD_MAP_FAILURE
}); 

//URL Parameters
export const URL_PARAMS_SET = 'URL_PARAMS_SET';
export const urlParamsSet = (urlOptions) => {
	return {
	type: URL_PARAMS_SET,
	payload:{urlOptions}
}}; 

///LAYER LOADING

export const LOAD_LAYER_IN_PROGRESS = 'LOAD_LAYER_IN_PROGRESS';
export const loadLayerInProgress = () => ({
	type: LOAD_LAYER_IN_PROGRESS
}); 

export const LOAD_LAYER_FAILURE = 'LOAD_LAYER_FAILURE';
export const loadLayerFailure = () => ({
	type: LOAD_LAYER_FAILURE
}); 

export const LOAD_LAYER_SUCCESS = 'LOAD_LAYER_SUCCESS';
export const loadLayerSuccess = () => ({
	type: LOAD_LAYER_SUCCESS
}); 

//LAYER CONTROL
export const LAYER_ADDED_TO_LIST = 'LAYER_ADDED_TO_LIST';
export const layerAddedToList = (layer) => ({
	type: LAYER_ADDED_TO_LIST,
	payload:{layer}
}); 
export const LAYER_ADDED_TO_LIST_BY_ID = 'LAYER_ADDED_TO_LIST_BY_ID';
export const layerAddedToListByIds = (layers) => ({
	type: LAYER_ADDED_TO_LIST_BY_ID,
	payload:{layers}
}); 
export const LAYER_REMOVED_FROM_LIST = 'LAYER_REMOVED_FROM_LIST';
export const layerRemovedFromList = (layer) => ({
	type: LAYER_REMOVED_FROM_LIST,
	payload:{layer}
}); 
export const LAYER_LIST_ADD_CUSTOM_LAYER = 'LAYER_LIST_ADD_CUSTOM_LAYER';
export const layerListAddCustomLayer = (layerId, layerItem) => ({
	type: LAYER_LIST_ADD_CUSTOM_LAYER,
	payload: {layerId, layerItem}
})
export const LAYER_LIST_READ = 'LAYER_LIST_READ';
export const readLayerList = (layerCompilation) => ({
	type: LAYER_LIST_READ,
	payload:{layerCompilation}
}); 

//SHOW TIP (really for meta data)
export const UPDATE_TIP = 'UPDATE_TIP';
export const updateTip = (layerTitle, layerDescription, mapService, authoritiveSource) => ({
	type: UPDATE_TIP,
	payload: {layerTitle, layerDescription, mapService, authoritiveSource}
}); 
export const CLEAR_TIP = 'CLEAR_TIP';
export const clearTip = () => ({
	type: CLEAR_TIP
}); 

////ALERT SECTION
export const CREATE_ALERT = 'CREATE_ALERT';
export const createAlert = (color, title, message) => ({
	type: CREATE_ALERT,
	payload: {color, title, message}
}); 

//This will probably be removed.
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const removeAlert = () => ({
	type: REMOVE_ALERT
}); 
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const clearAlerts = () => ({
	type: CLEAR_ALERTS
}); 
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const closeAlert = (key) => ({
	type: CLOSE_ALERT,
	payload: {key}
}); 



//Server Broswer (Replaces "CUSTOM_LAYER_*")
export const SERVER_BROWSER_ADD_PANEL = 'SERVER_BROWSER_ADD_PANEL';
export const serverBrowserAddPanel = (cacheObj) => ({
	type: SERVER_BROWSER_ADD_PANEL,
	payload: {cacheObj}
});
export const SERVER_BROWSER_REMOVE_PANEL = 'SERVER_BROWSER_REMOVE_PANEL';
export const serverBrowserRemovePanel = () => ({
	type: SERVER_BROWSER_REMOVE_PANEL
});

export const SERVER_BROWSER_CLEAR_ALL_PANELS = 'SERVER_BROWSER_CLEAR_ALL_PANELS';
export const serverBrowserClearAllPanels = () => ({
	type: SERVER_BROWSER_CLEAR_ALL_PANELS
});

export const SERVER_BROWSER_ADD_CACHE = 'SERVER_BROWSER_ADD_CACHE';
export const serverBrowserAddCache = (arcserviceUrl, cacheObj) => ({
	type: SERVER_BROWSER_ADD_CACHE,
	payload: {arcserviceUrl, cacheObj}
}); 
/*
export const serverBrowserAddCache = (arcserviceUrl, folders, services, layers) => ({
	type: SERVER_BROWSER_ADD_CACHE,
	payload: {arcserviceUrl, folders, services, layers}
}); 
*/
export const SERVER_BROWSER_IS_LOADING = 'SERVER_BROWSER_IS_LOADING';
export const serverBrowserIsLoading = () => ({
	type: SERVER_BROWSER_IS_LOADING
}); 
export const SERVER_BROWSER_LOAD_SUCCESSFUL = 'SERVER_BROWSER_LOAD_SUCCESSFUL';
export const serverBrowserLoadSuccessful = () => ({
	type: SERVER_BROWSER_LOAD_SUCCESSFUL
}); 
export const SERVER_BROWSER_LOAD_FAILED = 'SERVER_BROWSER_LOAD_FAILED';
export const serverBrowserLoadFailed = () => ({
	type: SERVER_BROWSER_LOAD_FAILED
}); 



//Graphing
//This means we're grabbing some initial data from the database
export const GRAPH_IS_INITIALIZING = 'GRAPH_IS_INITIALIZING';
export const graphIsInitializing = () => ({
	type: GRAPH_IS_INITIALIZING
}); 
export const GRAPH_IS_READY = 'GRAPH_IS_READY';
export const graphIsReady = (controlOptions) => ({
	type: GRAPH_IS_READY,
	payload: {controlOptions}
}); 
export const GRAPH_IS_NOT_READY = 'GRAPH_IS_NOT_READY';
export const graphIsNotReady = () => ({
	type: GRAPH_IS_NOT_READY
}); 



export const GRAPH_IS_LOADING = 'GRAPH_IS_LOADING';
export const graphIsLoading = () => ({
	type: GRAPH_IS_LOADING
}); 
export const GRAPH_IS_RETRIEVING_DATA = 'GRAPH_IS_RETRIEVING_DATA';
export const graphIsRetrievingData = () => ({
	type: GRAPH_IS_RETRIEVING_DATA
}); 
export const GRAPH_LOAD_SUCCESSFUL = 'GRAPH_LOAD_SUCCESSFUL';
export const graphLoadSuccessful = ({filters, chartObject}) => ({
	type: GRAPH_LOAD_SUCCESSFUL,
	payload: {filters, chartObject}
}); 
export const GRAPH_LOAD_FAILED = 'GRAPH_LOAD_FAILED';
export const graphLoadFailed = () => ({
	type: GRAPH_LOAD_FAILED
}); 
//So we have an action to close /hide the panel under the map.
export const GRAPH_CLOSED = 'GRAPH_CLOSED';
export const graphClosed = () => ({
	type: GRAPH_CLOSED
}); 
export const GRAPH_OPENED = 'GRAPH_OPENED';
export const graphOpened = () => ({
	type: GRAPH_OPENED
}); 
export const GRAPH_IS_RENDERED = 'GRAPH_IS_RENDERED';
export const graphIsRendered = () => ({
	type: GRAPH_IS_RENDERED
}); 
export const GRAPH_IS_RENDERING = 'GRAPH_IS_RENDERING';
export const graphIsRendering = () => ({
	type: GRAPH_IS_RENDERING
}); 






//Items below are deprecated

//CUSTOM LAYER STORAGE
export const CUSTOM_LAYER_STORAGE_IS_LOADING = 'CUSTOM_LAYER_STORAGE_IS_LOADING';
export const customLayerStorageIsLoading = () => ({
	type: CUSTOM_LAYER_STORAGE_IS_LOADING
}); 
export const CUSTOM_LAYER_STORAGE_LOAD_SUCCESSFUL = 'CUSTOM_LAYER_STORAGE_LOAD_SUCCESSFUL';
export const customLayerStorageLoadSuccessful = () => ({
	type: CUSTOM_LAYER_STORAGE_LOAD_SUCCESSFUL
}); 
export const CUSTOM_LAYER_STORAGE_LOAD_FAILED = 'CUSTOM_LAYER_STORAGE_LOAD_FAILED';
export const customLayerStorageLoadFailed = () => ({
	type: CUSTOM_LAYER_STORAGE_LOAD_FAILED
}); 

export const CUSTOM_LAYER_STORAGE_RESET = 'CUSTOM_LAYER_STORAGE_RESET';
export const customLayerStorageReset = () => ({
	type: CUSTOM_LAYER_STORAGE_RESET
}); 



export const CUSTOM_LAYER_STORAGE_ADD = 'CUSTOM_LAYER_STORAGE_ADD';
export const customLayerStorageAdd = (parent, arcserviceUrl, folders, services, layers) => ({
	type: CUSTOM_LAYER_STORAGE_ADD,
	payload: {parent, arcserviceUrl, folders, services, layers}
}); 

export const CUSTOM_LAYER_STORAGE_ADD_SERVICES = 'CUSTOM_LAYER_STORAGE_ADD_SERVICES';
export const customLayerStorageAddServices = (parent, serviceArray) => ({
	type: CUSTOM_LAYER_STORAGE_ADD_SERVICES,
	payload: {parent, serviceArray}
}); 
export const CUSTOM_LAYER_STORAGE_ADD_FOLDERS = 'CUSTOM_LAYER_STORAGE_ADD_FOLDERS';
export const customLayerStorageAddFolders = (parent, folderArray) => ({
	type: CUSTOM_LAYER_STORAGE_ADD_FOLDERS,
	payload: {parent, folderArray}
}); 
export const CUSTOM_LAYER_STORAGE_ADD_LAYERS = 'CUSTOM_LAYER_STORAGE_ADD_LAYERS';
export const customLayerStorageAddLayers = (parent, layerArray) => ({
	type: CUSTOM_LAYER_STORAGE_ADD_LAYERS,
	payload: {parent, layerArray}
}); 