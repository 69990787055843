import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const url =  "https://wrecks.nauticalcharts.noaa.gov/arcgis/rest/services/public_wrecks/Wrecks_And_Obstructions/MapServer";

const portalId = "46dafe60b47e46a78099c3e62bc935b3";

export const layer = new MapImageLayer({
  // url,
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Wrecks and Obstructions",
  popupTemplate: {
    title: "{vesslterms}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "feature_type",
            label: "Feature Type",
          },
          {
            fieldName: "yearsunk",
            label: "Year Sunk",
          },
          {
            fieldName: "history",
            label: "History",
          },
        ],
      },
    ],
  },
  layerDescription: "The Office of Coast Survey's Public Wrecks and Obstructions database contains information on the identified submerged wrecks and obstructions within the maritime boundaries of the United States. Information within the database includes the position of each feature (latitude and longitude) along with a brief description. Information to populate the database comes from what is currently available on the electronic navigational chart (ENC) and OCS's wrecks and obstruction information system.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const wrecks = {layerId: 'wrecks', type: 'layer', item: layer, meta: layer}
  

