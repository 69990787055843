import React, {
	useEffect,
	useState
} from "react";

import { useAnalyticsEventTracker } from "../../../../tools/googleAnalytics";
import {
	CalciteFlow, CalcitePanel,
	CalciteButton,
	CalciteAction,
	CalcitePickList,
	CalcitePickListItem,
	CalciteRadioGroup, CalciteRadioGroupItem
} from "@esri/calcite-components-react";
import { loadLayer } from '../../../../Flux/thunks';
import { updateTip } from "../../../../Flux/actions";
import { useDispatch } from "react-redux";
import { setModalContent, showModal } from "../../actions";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';


const MapLayersPanel = () => {
	const dispatch = useDispatch()
	// const mapLayersDiv = useRef(null);
	const gaEventTracker = useAnalyticsEventTracker('Layer Selection')
	const [panels, UpdatePanels] = useState([]);
	// const imperiledAgreement = useSelector(state => state.imperiledAgreement)
	const availableLayers = useSelector(state => state.availableLayers)

	// console.log('agreement', imperiledAgreement)


	useEffect(() => {

	}, [panels]);


	// useEffect(() => {
	// 	//console.log(arcgisMap, isMapLoading);

	//   	if (mapLayersDiv.current && arcgisMap.map) {

	// 	}
	// }, [arcgisMap]);


	const ClearAllPanels = () => {
		console.log('[MapLayersPanel] Clearing all panels')
		UpdatePanels([]);
	}


	/*
	Layer item: 
	
	*/


	const AddLayerToMap = (layerItem, _event) => {
		if (layerItem.layerId === 'imperiled') {
			dispatch(showModal(true))
			dispatch(setModalContent(layerItem.item.disclaimer))

		}

		if (layerItem.containsSublayers) {
			//If this is the main item, then pass layerItem.item
			if (layerItem.containsSublayers.main
				&& layerItem.containsSublayers.main.key
				&& layerItem.containsSublayers.main.key === layerItem.containsSublayers.useSublayer) {
				loadLayerOnMap(layerItem.item);
			} else {
				//find the sublayer
				layerItem.containsSublayers.sublayerItems.forEach((sublayerLayerItem) => {
					if (
						sublayerLayerItem.key
						&& sublayerLayerItem.key === layerItem.containsSublayers.useSublayer
					) {
						loadLayerOnMap(sublayerLayerItem.item);
					}
				})
			}
		} else {

			loadLayerOnMap(layerItem.item);

		}

		// if (layerItem.layerId === 'imperiled' && imperiledAgreement) {
		// 	loadLayerOnMap(layerItem.item)
		// }
		// console.log(layerItem.layerId === 'imperiled' && !imperiledAgreement)
		// if (layerItem.layerId === 'imperiled' && !imperiledAgreement){
		// 	dispatch(removeLayer(layerItem.item))
		// }
	};

	const RemoveServicePanel = (event) => {
		//console.log('Removing Panel: ', event);
		event.stopPropagation();
		UpdatePanels(panels.slice(0, -1));
	}
	const GoToMenu = (_e) => {
		//console.log('EVENT TEST BUTTON', _e);
		UpdatePanels([...panels,
		{ key: _e.target.value, title: _e.target.title, items: _e.target.items }]

		);
	}


	const CreateMenuSystem = (currentSelection, title = "Main Menu") => {
		// console.log('Creating Menu System with the following items: ', currentSelection);
		let panelKey = uuidv4()
		return (
			<CalcitePanel
				key={panelKey}
				heading={title}
				onCalcitePanelBackClick={(e) => { RemoveServicePanel(e); }}
			>
				<CalcitePickList>
					{currentSelection.filter((selectionItem) => {
						return selectionItem.hideInMenu !== true;
					}).map((selectionItem, key) => {
						if (selectionItem.type === 'group') {

							//SPECIAL CONDITION, 'custom' and no items
							if (selectionItem.id === 'custom' && selectionItem.items.length < 1) {
								return null;
							}


							return (

								<CalcitePickListItem key={key || selectionItem.id}
									heading={selectionItem.title}
									description={selectionItem.title}
									onCalciteListItemChange={(_e) => {
										if (_e.detail && _e.detail.selected) {
											//SearchServer(_e.detail.value);
											GoToMenu(_e);
											_e.target.selected = false;
										}
									}}
									title={selectionItem.title}
									items={selectionItem.items}
								>
									<CalciteAction slot="actions-end" icon="chevrons-right"
										title={selectionItem.title}
										items={selectionItem.items}
										onClick={(_e) => { GoToMenu(_e) }}></CalciteAction>
								</CalcitePickListItem>


							);
						} else if (selectionItem.type === 'layer') {
							const appendHelper = {};
							if (!selectionItem.meta) {
								appendHelper.disabled = true;
							}

							// console.log('layer type', selectionItem);
							return (
								<>
									<CalcitePickListItem key={key}
										heading={selectionItem.item.title}
										description={selectionItem.item.title}
										title={selectionItem.item.title}

										value={selectionItem.item}
										onCalciteListItemChange={(_e) => {
											if (_e.detail && _e.detail.selected) {
												//SearchServer(_e.detail.value);
												//AddLayerToMap()
											}
										}}
									>
										<CalciteAction
											key={key + `action`}
											slot="actions-end"
											icon="information"
											title="Metadata"
											{...appendHelper}
											onClick={(_e) => {
												console.log('Activating Metadata...', _e);
												if (selectionItem.meta) {
													const { layerTitle, layerDescription, mapService, authoritiveSource } = selectionItem.meta;
													onUpdateTip(layerTitle, layerDescription, mapService, authoritiveSource);
												}
											}}></CalciteAction>
										{
											selectionItem.containsSublayers ?
												<CalciteAction slot="actions-end" icon="sliders" onClick={(_e) => {
													document.getElementById(`${selectionItem.layerId}-sublayerOptions`)?.classList.toggle('hide-options')
												}}></CalciteAction>
												: null
										}

										<CalciteAction slot="actions-end" icon={
											selectionItem.type === 'tiles' ? 'tile-layer' : 'add-layer'
										} onClick={(_e) => {

											gaEventTracker(selectionItem.layerId)
											AddLayerToMap(selectionItem);


										}}></CalciteAction>

									</CalcitePickListItem>
									{
										selectionItem.containsSublayers ?

											<div id={`${selectionItem.layerId}-sublayerOptions`}
												className="content-area layer-options hide-options">

												<CalciteRadioGroup
													onCalciteRadioGroupChange={(e) => {
														console.log('[MapLayersPanel] Switched Sublayer Option: ', e);
														if (e.detail) {
															selectionItem.containsSublayers.useSublayer = e.detail;
														}
													}}
													width="full" layout="vertical">
													{
														/* */
														selectionItem.containsSublayers.main ?
															<CalciteRadioGroupItem value={selectionItem.containsSublayers.main.key}
																checked={true}
															>{selectionItem.containsSublayers.main.optionTitle}</CalciteRadioGroupItem>
															: null
													}
													{
														selectionItem.containsSublayers.sublayerItems ?
															selectionItem.containsSublayers.sublayerItems
																.map((sublayerItem) => {
																	return <CalciteRadioGroupItem value={sublayerItem.key}>{sublayerItem.optionTitle}</CalciteRadioGroupItem>;
																})
															: null
													}
												</CalciteRadioGroup>
											</div>
											: null

									}
								</>
							);
						}
						return null;

					})}
				</CalcitePickList>
			</CalcitePanel>
		);

	}

	const onUpdateTip = (layerTitle, layerDescription, mapService, authoritiveSource) => {
		dispatch(updateTip(layerTitle, layerDescription, mapService, authoritiveSource))
	}

	const loadLayerOnMap = (layer) => { dispatch(loadLayer(layer)) }


	return (
		<>
			<div className="content-area">


				<CalciteButton
					width={"full"}
					onClick={(_e) => {
						ClearAllPanels();
					}}
					iconStart="reset"
				>
					Go to Main Menu
				</CalciteButton>
			</div>
			<CalciteFlow>
				{CreateMenuSystem(availableLayers)}
				{panels.map((panelItem, key, index) => {
					return CreateMenuSystem(panelItem.items, panelItem.title, key);
				})}
			</CalciteFlow>
		</>
	);
}


export default MapLayersPanel;
