import { cableAreas } from "./coastNoaaCableAreas";
import { cables } from "./coastNoaaCables";
import { sediment} from "./coastNoaaSediment";
import { slrGroup } from "./maps2CoastNoaaDcSlrGroup";
import { twlGroup } from "./njmapsCoastFloodTwlGroup";
import { bathymetry } from "./portalDemHillshade";
import {imperiled} from './portalImperiled';
import { njdotCdf } from "./portalNjdotCdf";
import { nsspStations} from "./portalNsspStations";
import { outfall } from "./portalOutfall";
import { reportArea } from "./portalReportAreas";
import { sav1968 } from "./portalSAV1968";
import { sav1979 } from "./portalSAV1979";
import { savPoly1979 } from "./portalPolySAV1979";
import { sav8587 } from "./portalSAV8587";
import { sav9699 } from "./portalSAV9699";
import { sav2003 } from "./portalSAV2003";
import { sav2009 } from "./portalSAV2009";
import {waterQual} from "./portalWaterQual";
import { wrecks} from "./portalWrecks";
import { bottom } from "./portalBottom";
import { hardBottom } from "./portalHardBottom";
import {inventoryGroup} from "./inventory/inventoryGroup";

export const habitat = {
  id: 'habitat',
  title: "Habitat and Environmental Layers",
  type: 'group',
  items: [
    bottom,
    hardBottom,
    cableAreas,
    cables,
    sediment,
    slrGroup,
    twlGroup,
    bathymetry,
    imperiled,
    inventoryGroup,
    njdotCdf,
    nsspStations,
    outfall,
    reportArea,
    sav1968,
    sav1979,
    savPoly1979,
    sav8587,
    sav9699,
    sav2003,
    sav2009,
    waterQual,
    wrecks,
  ],
}
