import React from 'react';
import { connect } from "react-redux";
import { CalciteButton, CalciteTip } from "@esri/calcite-components-react";
import { clearTip } from '../Flux/actions';
//import { Link } from 'react-router-dom';

const FloatingCalciteTip = ({tipItem, onTipCleared}) => {
	if( !tipItem.active ) {
		return null;
	}
	return ( 
		<CalciteTip slot="center-row" className="floating-tip"
			heading={tipItem.layerTitle || "Layer Metadata"}
			onCalciteTipDismiss={(_e)=>{onTipCleared()}}
		>
			<p style={{overflowY: 'scroll', height: '60vh'}}>{tipItem.layerDescription || "No Description available"}</p>

			<div style={{display: 'flex', justifyContent: 'center', gap: '2rem'}}>
			{ tipItem.mapService ? <CalciteButton target="_blank" appearance='outline' color='blue' href={tipItem.mapService} icon-start="layer-map-service" label="Map Service Link">Map Service</CalciteButton> : null }
			
			{ tipItem.authoritiveSource ? <CalciteButton target="_blank" appearance='outline' color='blue' href={tipItem.authoritiveSource} icon-start="information" label="Layer Metadata"> More Information</CalciteButton> : null }
			</div>
		</CalciteTip>
	);
}


//what state items should this component have access to
const mapStateToProps = (state) => ({
	tipItem: state.tipItem
    //todos: state.todos,
	//arcgisMap: state.arcgisMap,
    //isMapLoading: state.isMapLoading,
    //isLayerLoading: state.isLayerLoading,
    //alertList: state.alertList
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	onTipCleared: ()=>{dispatch(clearTip())}
	//startLoadingMap: () => {dispatch(loadMap())},
	//onAlertClose: (key) => {dispatch(closeAlert(key))}
});

export default connect(mapStateToProps, mapDispatchToProps)(FloatingCalciteTip);
