//MAP LOADING
export const LOAD_MAP_IN_PROGRESS = 'LOAD_MAP_IN_PROGRESS';
export const loadMapInProgress = () => ({
	type: LOAD_MAP_IN_PROGRESS
}); 

export const LOAD_MAP_SUCCESS = 'LOAD_MAP_SUCCESS';
export const loadMapSuccess = view => ({
	type: LOAD_MAP_SUCCESS,
	payload:{view}
}); 
export const LOAD_MAP_FAILURE = 'LOAD_MAP_FAILURE';
export const loadMapFailure = () => ({
	type: LOAD_MAP_FAILURE
}); 
export const SHOW_MODAL = 'SHOW_MODAL';
export const showModal = value => ({
	type: SHOW_MODAL,
	payload: {value}
})

export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT'
export const setModalContent = content => ({
	type: SET_MODAL_CONTENT,
	payload:{content}
})

export const AGREE = 'AGREE'
export const agree = () => ({
	type: AGREE
}) 

export const DISAGREE = 'DISAGREE'
export const disagree = () => ({
	type: DISAGREE
}) 