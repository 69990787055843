import ImageryLayer from "@arcgis/core/layers/ImageryLayer";

const url = "https://gis.ngdc.noaa.gov/arcgis/rest/services/DEM_mosaics/DEM_tiles_mosaic_hillshade/ImageServer";

const portalId = "de9cc0c7d2d1462bbac3846b538e0975";

export const layer = new ImageryLayer({
  portalItem: {
    id: portalId,
  },
  title: "Digital Elevation Model Mosaic Bathymetry",
  popupTemplate: {
    title: "Digital Elevation Model Mosaic Bathymetry",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
      {
        type: "text",
        text: `<img src='https://gis.ngdc.noaa.gov/viewers/images/dem_color_scale.png' alt='legend for bathymetry map'/>`,
      },
    ],
  },
  visible: true,
  layerDescription: "Shaded relief visualization of a seamless bathymetry/topography mosaic of newer CUDEM digital elevation models at NOAA/NCEI.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const bathymetry = {layerId: 'bathymetry', type: 'layer', item: layer, meta: layer}
