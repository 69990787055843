import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

let url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Surface_Water_Based_Activities/FeatureServer";

let portalId = "ee2a6a1558f645bb8d1ccd99011e849b";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  popupTemplate: {
    title: "Surface Water Based Recreation",
    // renderer: renderer,
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
      {
        type: "fields",
        fieldInfos: [
          { fieldName: "kayak", label: "Kayak" },
          { fieldName: "swim", label: "Swim" },
          { fieldName: "windsurf", label: "Wind Surf" },
          { fieldName: "kiteboard", label: "Kiteboard" },
          { fieldName: "skimboard", label: "Skim Board" },
          { fieldName: "surf", label: "Surf" },
          { fieldName: "board_based", label: "Board Based" },
        ],
      },
    ],
  },
  title: "Surface Water Based Recreation",
  visible: true,
  layerDescription: "The data is displayed using a 1 kilometer by 1 kilometer grid for the U.S. Mid Atlantic coastline and offshore areas. The grid was created in ArcGIS 10.1 using the 'Create Fishnet' tool. A polygon shapefile was created covering the extent of the Mid Atlantic region and was used as the feature class the grid was created from. After the grid was created, a union was run to remove the grid cells that fell inland of the medium-resolution NOAA coastline. The final result was the 1 kilometer by 1 kilometer planning unit grid extending from New York down to Virginia, following the coastline, and extending offshore far enough to intersect all activity points. The grouped activity points were merged together and summarized to the planning unit grid, leaving each cell with a value equal to the number of points that fell within that cell. In the attribute table, the number of each individual activity that make up the activity group is available to see what activities took place within that particular cell.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const waterRec = {layerId: 'waterRec', type: 'layer', item: layer, meta: layer}
