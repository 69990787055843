import { primeFishing } from "./portalPrimeFishing";
// import { primePoints } from "./portalPrimePoints";
import { pubAccess } from "./portalPubAccess";
import { artReef } from "./portalArtReef";
import {  waterRec } from "./portalSurfWaterAct";

export const altLayers =
{
  id: 'altUse',
  title: "Public Access & Rec Area Layers",
  type: 'group',
  items: [pubAccess, 
    // primePoints, 
    primeFishing, artReef, waterRec],
};


