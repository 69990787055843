import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import getMapSvcOps from "../../../../../tools/getMapSvcOps";

// !! the portal item for the Estuarine Inventory doesn't return the sub-layer items

//** setting this up to loop through the url layer ids for the layers and generate the items */

const url = 'https://services1.arcgis.com/QWdNfRs7lkPq4g4Q/ArcGIS/rest/services/Estuarine_Inventory_of_New_Jersey/FeatureServer/'

const urlIdArray = [107, 33,34, 42, 16, 17, 18, 7, 8, 9, 24, 25, 87, 57, 47, 72, 73, 98, 65, 66]

const assocGroup = {
    id: 'assoc',
    title: "Associated Species",
    type: 'group',
    items: [],
};

urlIdArray.map(async (id) => {
    let item = new FeatureLayer({
        url: `${url}${id}`,
        visible: true,
        mapService: `${url}${id}`,
        popupEnabled: true,
        
    })
    getMapSvcOps(item).then((d) => {
        assocGroup.items.push(
            {
                key: `sav${id}`,
                id: `sav${id}`,
                layerId: `sav${id}`,
                type: 'layer',
                item,
                meta: {
                    title: d.title,
                    layerDescription: d.description,
                    mapService: `${url}${id}`,
                    authoritiveSource: `https://dep.nj.gov/njfw/`
                }
            }
        )
    })
    
})

export {assocGroup}
