import React, { useRef, 
	useEffect,
	useState 
} from "react";
import { connect } from "react-redux";
import { 
    
	CalciteAction,
    CalciteButton,
    CalciteFlow,
    CalciteInput,
	CalciteNotice,
	CalcitePanel,
	CalcitePickList,
	CalcitePickListGroup,
	CalcitePickListItem,

	//CalciteTabs,
	//CalciteTab,
	CalciteTabNav,
	CalciteTabTitle,
	//CalciteTree,
	//CalciteTreeItem

} from "@esri/calcite-components-react";

import {serverBrowserRemovePanel, serverBrowserClearAllPanels, layerListAddCustomLayer} from '../../../../../Flux/actions';
import {loadArcServerService, loadLayer} from '../../../../../Flux/thunks';
import PortalItem from "@arcgis/core/portal/PortalItem";

import axios from "axios";

//PULL THESE FUNCTIONS OUT WHEN READY
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import TileLayer from '@arcgis/core/layers/TileLayer';
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import Layer from "@arcgis/core/layers/Layer";
import ArcGISOnlinePortalNavigatorPanel from './ArcGISOnlinePortalNavigator/ArcGISOnlinePortalNavigatorPanel';

const ServerBrowserPanel = ({serverBrowser, loadServiceItems, removeServicePanel,clearAllServicePanels, addLayerToMapLayerModule, addLayerToMap, isMapLoading, arcgisMap, }) => {

	const mapLayersDiv = useRef(null);
	const [serverUrl, SetServerUrl] = useState('https://njmaps1.rad.rutgers.edu/arcgis/rest/services/');
	const [portalId, SetPortalId] = useState('6aca1928356e4c81915c86ccbae66d4e');
	const [portalItemError, SetPortalItemError] = useState({
		active: false,
		title: '',
		message: ''
	});

	const [disableSearchButton, SetDisableSearchButton] = useState(serverUrl==='');
	const [manualTabIndex, SetManualTabIndex] = useState('asb-by-url');
	
	useEffect(() => {
		//console.log(arcgisMap, isMapLoading);
	  	if (mapLayersDiv.current &&  arcgisMap.map) {

		}
	}, [arcgisMap]);
	


	const ClearAllServicePanels = () => {
		clearAllServicePanels();
	}

	const RemoveServicePanel = (event) => {
		console.log('Removing Panel: ', event);
		event.stopPropagation();
		removeServicePanel();
	}


	const SearchServer = (_overrideUrl = null) => {
		const useUrl = _overrideUrl ||serverUrl;
		console.log('Searching for service at', useUrl);

		loadServiceItems(useUrl);
	}
	
	const AddLayerToMap = (arcserviceUrl, layerId, _event) => {
		console.log('[ServerBrowserPanel] DUMB: Adding layer to map.', arcserviceUrl, layerId);
		//TODO: This item is kinda repeated in thunks@_createCustomLayerService. Maybe collapse this down?
		axios
		.get(arcserviceUrl, {params:{f: 'json'}})
		.then( (response) => {
			console.log('[ServerBrowserPanel] Loaded information from the Arcgis Service');
			if( response.data.code ) {
				console.error("[ServerBrowserPanel] Failed adding layer to map.")
			}else{
				console.log('[ServerBrowserPanel] ', response);
				//Special cases first
				if( response.data.serviceDescription && response.data.tileInfo ) {
					const newLayer = new TileLayer({
						url: arcserviceUrl,
						layerId 
					});
					
					addLayerToMapLayerModule(arcserviceUrl, newLayer);
					addLayerToMap(newLayer);
				}else if( response.data.type === 'Feature Layer') {
					const newLayer = new FeatureLayer({
						url: arcserviceUrl,
						layerId 
					});

					newLayer.when(() => {
						//Create a template of literally all the fields because screw it. :3
						newLayer.popupTemplate = newLayer.createPopupTemplate();
					});

					addLayerToMapLayerModule(arcserviceUrl, newLayer);
					addLayerToMap(newLayer);
				}else if( response.data.type === 'Tile Layer') {
					const newLayer = new TileLayer({
						url: arcserviceUrl,
						layerId 
					});
					
					addLayerToMapLayerModule(arcserviceUrl, newLayer);
					addLayerToMap(newLayer);
				}else if( response.data.type === 'Raster Layer') {

					//console.log(layerId, arcserviceUrl.lastIndexOf(`/${layerId}`));
					//console.log(arcserviceUrl.substring(0,  arcserviceUrl.lastIndexOf(`/${layerId}`) ));

					const newLayer = new MapImageLayer({
						url: arcserviceUrl.substring(0,  arcserviceUrl.lastIndexOf(`/${layerId}`) ),
						sublayers: [{id: layerId, visible: true, title: response.data.name }]
					});
					
					addLayerToMapLayerModule(arcserviceUrl, newLayer);
					addLayerToMap(newLayer);
				}else if( response.data.type === 'Group Layer' ) {
					const newLayer = new MapImageLayer({
						url: arcserviceUrl.substring(0,  arcserviceUrl.lastIndexOf(`/${layerId}`) ),
						sublayers: response.data.subLayers, // [{id: layerId, visible: true, title: response.data.name }]
						title: response.data.name
					});
					
					addLayerToMapLayerModule(arcserviceUrl, newLayer);
					addLayerToMap(newLayer);
					/*
					const temp = arcserviceUrl.substring(0,  arcserviceUrl.lastIndexOf(`/`)).substring(0,  arcserviceUrl.lastIndexOf(`/`));
					
					console.log('really? ',temp, arcserviceUrl.substring(0,  arcserviceUrl.lastIndexOf(`/`)), arcserviceUrl.lastIndexOf(`/`));

					console.log('really...', temp.substring(0,  temp.lastIndexOf(`/`) ))
					const newLayer = new SubtypeGroupLayer({
						url: temp.substring(0,  temp.lastIndexOf(`/`) ),
						sublayers: response.data.subLayers,
						title: response.data.name
					});
						
					addLayerToMapLayerModule(arcserviceUrl, newLayer);
					addLayerToMap(newLayer);
					*/
				}else{
					console.warn('[Import Data] Layer type not implemented: ', response.data.type);
				}
				


			}
			return response;
		})
		.catch( (response) => {
			console.error("[ServerBrowserPanel] Failed adding layer to map.", response)

			return response;
		})


	}

	const UpdateServerUrl = (event) => {
		
		const useUrl = ( event.type && event.type === 'drop' ) ? event.dataTransfer.getData('text') : event.target.value;
		
		SetDisableSearchButton(!useUrl.length);
		SetServerUrl(useUrl);
	};


	//Searching by portal id
	
	const SearchForPortalItem = async (_overridePortalId) => {
		const usePortalId = _overridePortalId ||portalId;
		console.log('Searching with portal id', usePortalId);

		const item = new PortalItem({
			id: usePortalId
		});
		item.load()
		.then( (returnItem) => {
			console.log('This portal item has returned successfully. ', returnItem);
			if( returnItem.isLayer ) {
				const portalLayer = Layer.fromPortalItem({
					portalItem:{id:usePortalId}
				});
				//Currently if we're not conscious about loading stuff, the title may not load. 
				 portalLayer.then((returnLayer) => {
					//Loads the resources referenced by this class. (https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-Layer.html#load)
					returnLayer.load().then( (newLayer)=> {
						newLayer.when(() => {
							if(newLayer.createPopupTemplate ) {
								newLayer.popupTemplate = newLayer.createPopupTemplate();
							}

							addLayerToMapLayerModule(usePortalId, newLayer);
							addLayerToMap(newLayer);
						});
					});
				});
			}
			return null;
		})
		.catch( (response) => {
			console.log('This portal item FAILED. ', response);
			
			if( response.message ) {
				SetPortalItemError({
					active: true,
					title: 'Failed to add',
					message: response.message
				});
			}else{
				SetPortalItemError({
					active: true,
					title: 'Failed to add',
					message: 'Unknown error.'
				});
			}
		})
		;

		//loadServiceItems(useUrl);
	}






	const UpdatePortalId = (event) => {
		//console.log('Updating portal url.', event);
		event.preventDefault();
		//event.stopPropagation();
		const usePortalId = ( event.type && event.type === 'drop' ) ? event.dataTransfer.getData('text') : event.target.value;

		//SetDisableSearchButton(!useUrl.length);
		SetPortalId(usePortalId);
	};


	//There seems to be a problem with calcitebutton and setting disabled to false, it still keeps the prop `disabled=""` 
	//	and continues to disable the prop instead of letting it be clickable

	const _OutputFolders = (folders) => {
		if( folders.length < 1 ) {
			return null;
		}

		return (<CalcitePickListGroup groupTitle="Folders">
			{folders.map( (folderItem, key) => {

				return (
					<CalcitePickListItem key={key} heading={folderItem.name} description={folderItem.name} 
					onCalciteListItemChange={ (evt) => { 
						if( evt.detail && evt.detail.selected) {
							console.log(evt);
							SearchServer(evt.detail.value);
							evt.target.selected=false;
						}
					}}
					value={folderItem.url} 
					><CalciteAction slot="actions-end" icon="chevron-right" onClick={ (e) => { SearchServer(folderItem.url); }}></CalciteAction></CalcitePickListItem>
				);
			})}
		</CalcitePickListGroup>);
	}
	const _OutputServices = (services) => {
		if( services.length < 1 ) {
			return null;
		}

		return (<CalcitePickListGroup groupTitle="Services">
			{services.map( (serviceItem, key) => {

				return (
					<CalcitePickListItem key={key} heading={serviceItem.name} description={serviceItem.name} 
					value={serviceItem.url} 
					onCalciteListItemChange={ (evt) => { 
						if( evt.detail && evt.detail.selected) {
							SearchServer(evt.detail.value);
							evt.target.selected=false;
						}
					}}
					>
					{
						serviceItem.hasTiles ?
						<CalciteAction slot="actions-end" icon="tile-layer" onClick={ () => { AddLayerToMap(serviceItem.url); }}></CalciteAction>
						: null
					}
					<CalciteAction slot="actions-end" icon="chevron-right" onClick={ () => { SearchServer(serviceItem.url); }}></CalciteAction>
					</CalcitePickListItem>
				);
			})}
		</CalcitePickListGroup>);
	}
	
	const _OutputLayers = (layers) => {
		if( layers.length < 1 ) {
			return null;
		}

		return (
			<CalcitePickList multiple={true}>
				<CalcitePickListGroup groupTitle="Layers">
			{layers.map( (layerItem, key) => {

				return (
					<CalcitePickListItem key={key} heading={layerItem.name} description={layerItem.name} 
					value={layerItem.url} 
					onCalciteListItemChange={ (evt) => { 
						if( evt.detail && evt.detail.selected) {
							//SearchServer(evt.detail.value);
							//AddLayerToMap()
						}
					}}
					><CalciteAction slot="actions-end" icon={
						layerItem.type === 'tiles' ? 'tile-layer' : 'add-layer'
					 } onClick={ () => { 
						//SearchServer(layers.url); 
						AddLayerToMap(layerItem.url, layerItem.layerId);
					}}></CalciteAction></CalcitePickListItem>
				);
			})}
		</CalcitePickListGroup>
		</CalcitePickList>
		);
	}
	
	return (
		<div className="content-area">

			<CalciteTabNav style={{marginBottom:'.5em'}} slot="tab-nav" layout="center"
				onCalciteTabChange={(_e) =>{
					//console.log('Tab Changed; ', _e);
					SetManualTabIndex(_e.detail.tab);
				}}
			>
				<CalciteTabTitle tab="asb-by-url">URL</CalciteTabTitle>
				{/*
				//Temporarily Disabled.
				<CalciteTabTitle tab="asb-portal">Portal Item</CalciteTabTitle>
				*/}
				<CalciteTabTitle tab="asb-arcgis-online">ArcGIS Online</CalciteTabTitle>
			</CalciteTabNav>

			{/* Import Data / ArcServer Browser: Connect to their arcgis online account and build a browser. */}
			<ArcGISOnlinePortalNavigatorPanel manualTabIndex={manualTabIndex} />




			{/* Import Data / ArcServer Browser: Get Portal Item By ID */}
			
			<CalcitePanel
				style={{display: ( manualTabIndex === 'asb-portal' ? 'flex' : 'none')}}
			>
				<div className="content-area">
					Please enter the layer&rsquo;s Portal Id Below: 
					<CalciteInput type="text" placeholder="6aca1928356e4c81915c86ccbae66d4e" label="Enter your layer's portal id:" 
						onKeyUp={(e) => { UpdatePortalId(e) }}
						onChange={(e) => { UpdatePortalId(e) }}
						onDrop={(e) => { UpdatePortalId(e) }}
						value={portalId}
					/>
					<p style={{wordWrap:'anywhere'}}><em>Example Portal Id: <code>6aca1928356e4c81915c86ccbae66d4e</code></em></p>
					<CalciteButton 
						width="full"
						onClick={(_e) => {
							SearchForPortalItem();
						}}
					>Add Layer By Portal Id</CalciteButton>
					<div style={{marginTop:'.5em'}}>
					<CalciteNotice color="red" active={portalItemError.active || null}
					dismissible={true}
					iconStart="error"
					onCalciteNoticeClose={(_e)=>{
						console.log('Notice on close')
						SetPortalItemError({
							active: false,
							title: '',
							message: ''
						});
					}}
					>
						<div slot="title">{portalItemError.title}</div>
						<div slot="message">{portalItemError.message}</div>
					</CalciteNotice>
					</div>
				</div>
			</CalcitePanel>


			{/* Import Data / ArcServer Browser: By URL*/}
			<CalcitePanel
				style={{display: ( manualTabIndex === 'asb-by-url' ? 'flex' : 'none')}}
			>
				<CalciteButton
					width={"full"}
					onClick={(_e) => { 
						ClearAllServicePanels();
					}}
					iconStart="reset"
					disabled={ serverBrowser.length === 0 || null}
				>
					Reset Import From URL
				</CalciteButton>
				<CalciteFlow
					style={{display: ( manualTabIndex === 'asb-by-url' ? 'flex' : 'none')}}
				>
					<CalcitePanel>
						<div className="content-area">
						<p>This tool allows you to add data from existing map services such as those provided by Federal, State, and Local agencies or non-profits. To access the layers from a map service, please enter the URL to the ArcGIS Server below in the format <code style={{wordBreak: 'break-all'}}>https://servername/arcgis/rest/services</code>. To add a specific map service from a server, enter the URL to the map service below in the format <code style={{wordBreak: 'break-all'}}>https://servername/arcgis/rest/services/foldername/mapservername/MapServer/layernumber(optional)</code>. Please note that some links to map services provided by ArcGIS Portals through their 'View API Resource' option append additional parameters after the layer number, please do not include any of these extra parameters when using this tool.</p>
							<CalciteInput type="url" placeholder="https://njmaps1.rad.rutgers.edu/arcgis/rest/services/" label="Enter your server's URL:" 
								onKeyUp={(e) => { UpdateServerUrl(e) }}
								onChange={(e) => { UpdateServerUrl(e) }}
								onDrop={(e) => { UpdateServerUrl(e) }}
								value={serverUrl}
							/>
							{/* <p><em>Example URL: https:// njmaps1.rad.rutgers.edu/ arcgis/ rest/ services/</em></p> */}
							{/* Null must be passed to ignore attributes, not `false`... */}
							<CalciteButton 
								width="full"
								onClick={ () => { 
									SearchServer();
								}}
								disabled={disableSearchButton||null} 
								>Find Layers At URL</CalciteButton>
						</div>
					</CalcitePanel>
					{serverBrowser.map( (serverBrowserItem, key) => {
						console.log('Items in this panel: ', serverBrowserItem);
						//ACCORDIAN HERE!!!!!!!!!!
						return (
							<>
							{serverBrowserItem.serviceDescription ? <div><h4>Service Description</h4><p>{serverBrowserItem.serviceDescription}</p></div> : null}
							<CalcitePanel key={key}
							heading={serverBrowserItem.title}
							onCalcitePanelBackClick={(e)=>{RemoveServicePanel(e);}}
							>
								<CalcitePickList>
								{_OutputFolders(serverBrowserItem.folders)}
								{_OutputServices(serverBrowserItem.services)}
								</CalcitePickList>

								{_OutputLayers(serverBrowserItem.layers)}
							</CalcitePanel>
							</>
						);
					})}
				</CalciteFlow>
			</CalcitePanel>
			


		</div>
	);
}


const mapStateToProps = (state) => ({
    serverBrowser: state.serverBrowser,
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	loadServiceItems: (arcserviceUrl) => {dispatch(loadArcServerService(arcserviceUrl))},
	removeServicePanel: () => {dispatch(serverBrowserRemovePanel())},
	clearAllServicePanels: () => {dispatch(serverBrowserClearAllPanels())},
	addLayerToMapLayerModule: (layerKey, layerItem) =>{dispatch(layerListAddCustomLayer(layerKey, layerItem))},
	addLayerToMap: (layer) => {dispatch(loadLayer(layer))},
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerBrowserPanel);

