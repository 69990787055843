import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Submerged_Aquatic_Vegetation_in_Barnegat_Bay_2009/FeatureServer";

const portalId = "a24844770d1b4d54a2755be27d7e8954";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Submerged Aquatic Vegetation in Barnegat Bay 2009",
  popupTemplate: {
    title: "Submerged Aquatic Vegetation Barnegat 2009",
    content: `Class Number: {CLASSNUM}<br/> layerDescription: {CLASSDESC}`,
  },
  layerDescription: "The purpose of this study was to map the areal extent and density of submerged aquatic vegetation within the Barnegat Bay and Little Egg Harbor, New Jersey as part of ongoing monitoring for the Barnegat Bay Partnership. Our goal was to develop a methodology that was comparatively objective in delineating bed boundaries and characterizing seagrass density, was cost-effective and easily repeatable for future monitoring purposes. We applied this conceptual framework to the mapping and spatial analysis of seagrass beds and the broader benthic environment in Barnegat Bay-Little Egg Harbor estuary in New Jersey, USA. SAV is a key indicator of the health of the Barnegat Bay estuary, and is under constant stress from a number of sources. These stresses cause changes in seagrass bed characteristics which in turn make frequent monitoring of this habitat type necessary.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const sav2009 = {layerId: 'sav2009', type: 'layer', item: layer, meta: layer}
  

