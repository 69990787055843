// Water Quality (Shellfish Harvest Prohibited/Seasonally Approved)

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://mapsdep.nj.gov/arcgis/rest/services/Features/Environmental_admin/MapServer/2";

const portalId = "e8153c69f8e64d77a770d0eb6f51e9fd";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Shellfish Water Quality Classification",
  popupTemplate: {
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
      {
        type: "fields",
        fieldInfos: [
          { fieldName: "STATUS", label: "Status" },
          { fieldName: "ACRES", label: "Acres" },
        ],
      },
      {
        type: "text",
        text: "This layer is a graphic representation of NJ coastal waters classified according to regulations of shellfish harvest. Waters are classified in one of five categories: Prohibited - harvest not allowed under any conditions. Special Restricted - harvest allowed with a special permit requiring further purification of the shellfish before sale. Seasonal (Nov - Apr) - and Seasonal (Jan - Apr) - where harvest is permitted only during certain seasons of the year. The final category is Approved - harvest permitted under any conditions. Classification of the waters is based on the National Shellfish Sanitation Program.",
      },
    ],
  },
  layerDescription: "This layer is a graphic representation of NJ coastal waters classified according to regulations of shellfish harvest. Waters are classified in one of five categories: Prohibited - harvest not allowed under any conditions. Special Restricted - harvest allowed with a special permit requiring further purification of the shellfish before sale. Seasonal (Nov - Apr) - and Seasonal (Jan - Apr) - where harvest is permitted only during certain seasons of the year. The final category is Approved - harvest permitted under any conditions. Classification of the waters is based on the National Shellfish Sanitation Program.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const waterQual = {layerId: 'waterQual', type: 'layer', item: layer, meta: layer}
  