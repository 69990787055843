import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://coast.noaa.gov/arcgis/rest/services/MarineCadastre/NavigationAndMarineTransportation/MapServer/4";

export const layer = new FeatureLayer({
  url,
  title: "Charted Submarine Cables",
  popupTemplate: {
    title: '{description}',
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
    ],
  },
  visible: true,
  layerDescription: "NOAA Charted Submarine Cables",
  mapService: url,
  authoritiveSource: `https://marinecadastre.gov/`,
});

export const cables = {layerId: 'cables', type: 'layer', item: layer, meta: layer}
