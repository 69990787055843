import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

let url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Aquaculture_Development_Zones_ADZ/FeatureServer";

let portalId = "99dd231cf68c4047aea0c28d166a7dd1";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  title: "Aquaculture Development Zones",
  popupTemplate: {
    title: "{Area}",
  },
  visible: true,
  layerDescription: "The data layer provides the location of all Aquaculture Development Zone leases in the Delaware Bay. These leases are used exclusively for the planting and cultivating of shellfish by the lessee. Leases are granted by the New Jersey Shellfisheries Council, upon approval from the Commissioner of the NJDEP. This authority of the Council is to Grant Leases is by Title 50, NJSA 50:1-24 and codified in the Leasing of Atlantic Coast Bottom for Aquaculture regulations at NJAC 7:25-24. This layer is updated as needed. This layer changes as needed depending on leasing transactions, i.e. Council actions to approved pending leases, leases vacated due to non-payment or lessee action. This layer was created to facilitate the leasing program within the Delaware Bay.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const delAquaDev = {layerId: 'delAquaDev', type: 'layer', item: layer, meta: layer}