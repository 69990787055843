import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadLayer, loadMap, removeLayer } from '../../Flux/thunks';
import { CalciteAction, CalciteActionBar, CalciteActionGroup, 
	CalciteShellPanel, CalcitePanel, 
	CalciteShell,
	 CalciteShellCenterRow,
	 CalciteModal,
	 CalciteButton, 
} from "@esri/calcite-components-react";
import Map from './Components/Map';
import BasemapGalleryPanel from './Panels/Core/BasemapGalleryPanel';
import LegendPanel from './Panels/Core/LegendPanel';
import AlertList from './Panels/Core/AlertList';
import MapLayersPanel from './Panels/Core/MapLayersPanel';
import ServerBrowserPanel from './Panels/Core/ServerBrowserPanel';
import SaveSharePrintPanel from './Panels/Core/SaveSharePrintPanel';
import FindLocationPanel from './Panels/Core/FindLocationPanel';
import LayerControlPanel from './Panels/Core/LayerControlPanel';
import FloatingCalciteTip from '../../Common/FloatingCalciteTip';
import { useSelector, useDispatch } from 'react-redux';
import { agree, disagree, showModal } from './actions'
import Feedback from './Components/Feedback'
import Thanks from './Components/Thanks';

const MapPage = ({ isMapLoading, isLayerLoading, arcgisMap, getUrlOptions, startLoadingMap }) => {

	const dispatch = useDispatch()

	const layerList = useSelector(state => state.layerList)
	const {isVisible: modalVisible, data: modalContent} = useSelector(state => state.modal)
	const availableLayers = useSelector(state => state.availableLayers)
	
	
	//In the tutorial, I didn't need to add the function into use effect
  	let { shareMapKey } = useParams();
	const [primaryToggle, SetPrimaryToggle] = useState(
		(getUrlOptions && getUrlOptions.isMinimal) ? false : true
	);
	

	const [showPrimaryContent, SetShowPrimaryContent] = useState(
		(getUrlOptions && getUrlOptions.isMinimal ? 'legend' : null)
	);
	
	const [leftBarExpand, setLeftBarExpand] = useState(true)


	const TogglePrimaryPanel = ( primaryContent ) => {
		
		//primary content already open
		if( showPrimaryContent === primaryContent ) {
			SetPrimaryToggle(true);
			SetShowPrimaryContent(null);
			return;
		}

		SetPrimaryToggle(false);
		SetShowPrimaryContent(primaryContent);
		return;
	}
	
	useEffect( () => {
		startLoadingMap(shareMapKey);
	}, [startLoadingMap, shareMapKey]);

//CalciteActions can have loading="true" to show a loader.
	

	const handleAgree = () => {
		let habitat = availableLayers.filter(group => group.id === 'habitat')[0]
		let layer = habitat.items.filter(layer => layer.layerId === 'imperiled')[0].item
		dispatch(agree())
		dispatch(showModal(false))
		dispatch(loadLayer(layer))
	}
	const handleDisagree = () => {
		let layer = layerList.filter(layer => layer.ora.layerId === 'imperiled')[0]
		// console.log('map',arcgisMap)
		
		dispatch(disagree())
		dispatch(removeLayer(layer))
		dispatch(showModal(false))
		
		
	}

	return ( 
		<CalciteShell>
			<h2 style={{margin: '1rem'}} slot='header'>
			New Jersey Aquaculture Siting Information Tool
				</h2>
			<CalciteShellPanel slot="primary-panel" position="start" widthScale="m" detached="true" detachedHeightScale="l" collapsed={primaryToggle} >
				
				<CalciteActionBar hidden={getUrlOptions ? getUrlOptions.isMinimal : null} slot="action-bar" 
				expanded={leftBarExpand} calciteActionBarToggle={() => setLeftBarExpand(!leftBarExpand)}
				>
					
					<CalciteActionGroup>
						<CalciteAction active={showPrimaryContent==='layer-control' || null} text="Layer Control" icon="map-contents" onclick={(_e) => { TogglePrimaryPanel('layer-control'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='map-layers' || null} text="Map Layers" icon="layers" onclick={(_e) => { TogglePrimaryPanel('map-layers'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='server-browser' || null} text="Import Data" icon="data-magnifying-glass" onclick={(_e) => { TogglePrimaryPanel('server-browser'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='basemaps' || null} text="Basemap" icon="basemap" onclick={(_e) => { TogglePrimaryPanel('basemaps'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='find-location' || null} text="Find Location" icon="search" onclick={(_e) => { TogglePrimaryPanel('find-location'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='save' || null} text="Save / Share / Print" icon="save" onclick={(_e) => { TogglePrimaryPanel('save'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='legend' || null} text="Legend" icon="legend" onclick={(_e) => { TogglePrimaryPanel('legend'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent === 'feedback' || null} text="Feedback" icon="envelope" onClick={()=> TogglePrimaryPanel('feedback')}></CalciteAction>
						<CalciteAction active={showPrimaryContent === 'thanks' || null} text="Acknowledgements" icon="open-book" onClick={()=> TogglePrimaryPanel('thanks')}></CalciteAction>
					</CalciteActionGroup>
				</CalciteActionBar>
				

				<CalcitePanel  
					open={showPrimaryContent === 'server-browser'}
					style={{marginTop: '0', display:( showPrimaryContent === 'server-browser' ? 'flex' : 'none' )}}
					heading="Import Data"
					summary="Browse layers online"
					widthScale='m'
					>
					
					<ServerBrowserPanel isMapLoading={isMapLoading} arcgisMap={arcgisMap} />
				</CalcitePanel>
				
				<CalcitePanel  
					
					dismissible={false}
					open={showPrimaryContent === 'map-layers'}
					style={{marginTop: '0', display:( showPrimaryContent === 'map-layers' ? 'flex' : 'none' )}}
					heading="Map Layers"
					summary="Add layers to map here.">
					<MapLayersPanel isMapLoading={isMapLoading} arcgisMap={arcgisMap} />
				</CalcitePanel>


				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'basemaps'}
					style={{marginTop: '0', display:( showPrimaryContent === 'basemaps' ? 'flex' : 'none' )}}
					heading="Basemaps"
					summary="Select a basemap">
					<BasemapGalleryPanel arcgisMap={arcgisMap} isMapLoading={isMapLoading}/>
					
				</CalcitePanel>
				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'find-location'}
					style={{marginTop: '0', display:( showPrimaryContent === 'find-location' ? 'flex' : 'none' )}}
					heading="Find Location"
					summary="Find a point on the map.">
					<FindLocationPanel arcgisMap={arcgisMap} isMapLoading={isMapLoading} />
				</CalcitePanel>


				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'save'}
					style={{marginTop: '0', display:( showPrimaryContent === 'save' ? 'flex' : 'none' )}}
					heading="Save / Share / Print"
					summary="Save, share, or print your map."
					loading={isMapLoading}
					>
					<SaveSharePrintPanel arcgisMap={arcgisMap} isMapLoading={isMapLoading} />
				</CalcitePanel>

				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'legend'}
					style={{marginTop: '0', display:( showPrimaryContent === 'legend' ? 'flex' : 'none' )}}
					heading="Legend"
					summary="The Legend for the map.">
					<LegendPanel arcgisMap={arcgisMap} isMapLoading={isMapLoading} />
				</CalcitePanel>
				<CalcitePanel  
					// dismissible={false}
					open={showPrimaryContent === 'feedback'}
					style={{marginTop: '0', display:( showPrimaryContent === 'feedback' ? 'flex' : 'none' )}}
					heading="Questions or Concerns?"
					// summary="The Legend for the map."
					>
					<Feedback/>
				</CalcitePanel>
				<CalcitePanel  
					// dismissible={false}
					open={showPrimaryContent === 'thanks'}
					style={{marginTop: '0', display:( showPrimaryContent === 'thanks' ? 'flex' : 'none' )}}
					heading="Acknowledgements"
					// summary="The Legend for the map."
					>
					<Thanks/>
				</CalcitePanel>
				<CalcitePanel 
					dismissible={false}
					loading={isMapLoading}
					open={showPrimaryContent === 'layer-control'}
					style={{marginTop: '0', display:( showPrimaryContent === 'layer-control' ? 'flex' : 'none' )}}
					heading="Layer Control"
					summary="Adjust how layers are shown on the map">
					<LayerControlPanel arcgisMap={arcgisMap} />
				</CalcitePanel>
			</CalciteShellPanel>
			

			<CalciteShellCenterRow slot="center-row">
			
			 {modalVisible && <CalciteModal aria-labelledby="modal-title" active disableCloseButton disableEscape disableOutsideClose>
            <div slot="content">
               <p>{modalContent}</p>
			   <div style={{display: 'flex', justifyContent: 'space-between'}}>
			   <CalciteButton appearance='outline' color='red' onClick={handleDisagree}>Disagree</CalciteButton> 
			   <CalciteButton onClick={handleAgree}>Agree</CalciteButton> 
			   </div>
			</div>
        		</CalciteModal> }
			
				<Map  arcgisMap={arcgisMap} loading={isMapLoading || isLayerLoading} />
			</CalciteShellCenterRow>

			<AlertList />
			<FloatingCalciteTip />
		</CalciteShell>

	
	
	);
}
//<Map view={view} webmap={webmap} />


//what state items should this component have access to
const mapStateToProps = (state) => ({
    //todos: state.todos,
	arcgisMap: state.arcgisMap,
    isMapLoading: state.isMapLoading,
    isLayerLoading: state.isLayerLoading,
    getUrlOptions: state.getUrlOptions
    //alertList: state.alertList
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	startLoadingMap: (shareMapKey) => {dispatch(loadMap(shareMapKey))},
	//onAlertClose: (key) => {dispatch(closeAlert(key))}
});

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
