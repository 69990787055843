import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/New_Jersey_Direct_Market_Oyster_Beds_Boundaries/FeatureServer";

const portalId = "ce55904172f345f49739334882726e80";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  title: "Delaware Bay Oyster Bed Boundaries",
  popupTemplate: {
    title: "{Bed}",
  },
  visible: true,
  layerDescription: "This layer shows higher quality oyster bedboundarieson the New Jersey side of the Delaware Bay. These beds are considered natural seed beds and contain a large and valuable resource to the state. This layer is for information purposes only.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const delOysterBounds = {layerId: 'delOysterBounds', type: 'layer', item: layer, meta: layer}
