import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Submerged_Aquatic_Vegetation_in_Barnegat_Bay_1968/FeatureServer";

const portalId = "fe8953a220fd4ddbaf75512979a4227c";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Submerged Aquatic Vegetation in Barnegat Bay 1968",
  popupTemplate: {
    title: "Submerged Aquatic Vegetation in Barnegat Bay 1968",
    content: `Species: {SPECIES}`,
  },
  layerDescription: "The sav68.shp was created as part of the 'Landscape Scale Approaches to Coastal Habitat Conservation - Barnegat Bay' study. This study focuses on the loss of submerged aquatic vegetation (SAV) that is occuring in Barnegat Bay as a result of human modification and decreasing water quality in the bay and watershed across time. CRSSA has compiled SAV studies conducted in the 1960's, 1970's, 1980's, 1990's to facilitate habitat loss assessment in Barnegat Bay. As of this writing, CRSSA continues to map and monitor submerged aquatic vegetation as part of its on-going Coastal Studies Program.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const sav1968 = {layerId: 'sav1968', type: 'layer', item: layer, meta: layer}
