import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Submerged_Aquatic_Vegetation_1979/FeatureServer/1";

const portalId = "6729522a12b4429ebbfa6681bc8f831d";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  layerId: 1,
  visible: true,
  title: "Atlantic Coast Submerged Aquatic Vegetation 1979",
  popupTemplate: { title: "Survey Year: {SURVEY_YEAR}", content: "{NOTE}" },
  layerDescription: "This is a GIS layer of polygons representing submerged aquatic vegetation (SAV) along the Atlantic coastline of New Jersey in 1979, originally mapped by Macomber and Allen (1979) and digitized by the NJ Division of Fish and Wildlife in 2021. This layer is a “snapshot in time” of relative distribution of SAV, and SAV may be found presently in areas not previously evaluated or in areas where it was not historically observed. Species composition may change over time. This layer charts both SAV (vascular vegetation) and macroalgae species. Only vascular SAV is regulated by the Coastal Zone Management Rules at N.J.A.C. 7:7-9.6. This snapshot-in-time distribution data documents where resources were observed, but does not exclude the possibility of finding resources in additional locations at another point in time. The absence of an observed resources does not necessarily indicate that habitat is unsuitable, unless there is sufficient multi-year data with a history of resource absence. All readers are advised that the complete regulated SAV resource history should be evaluated when applying for a waterfront development permit. If no information is available, please contact the Bureau of Shellfisheries for guidance relative to waterfront development permitting. The Bureau may have information on the area that was collected during different surveys or is not yet published. Additionally, there may be minor differences (gaps, adjustments) between the data and the existing coastline. If a gap or adjustment is observed, the reader should consult the original hard copy maps (or digital scans thereof) to determine if this is a product of the GIS-interpretation of if the gap/adjustment represents the terminus of the polygon or line. In most cases, the mapped area should be interpreted as extending to the shoreline.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const savPoly1979 = {layerId: 'savPoly1979', type: 'layer', item: layer, meta: layer}


