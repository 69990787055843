import { CalciteBlock, CalciteButton, CalciteLabel, CalciteRadioGroup, CalciteRadioGroupItem, CalciteSlider } from '@esri/calcite-components-react';
import React from 'react'

const LayerListBlock = ({layerItem, handleVisibility, handleRemove}) => {
    
    // passing null or false to a radio group will actually remove the button, which is dumb, we'll conditional props here.
    const visibleOn = {};
    const visibleOff = {};
    //console.log('Is this item visible? ', layerItem.visible, layerItem);
    if (layerItem.visible) {
        visibleOn.checked = true;
    } else {
        visibleOff.checked = true;
    }
    //console.log('Is this item visible? ', visibleOn, visibleOff, layerItem.visible, layerItem);
  return (
    <CalciteBlock collapsible heading={layerItem.title} summary={layerItem.type} layerId={layerItem.id} dragHandle >
        <CalciteLabel layout="inline">
            Show Layer:
            <CalciteRadioGroup scale="s"
                onClick={({target}) => {
                    let { value } = target
                    handleVisibility(layerItem, value);
                }}>
                <CalciteRadioGroupItem value="on" {...visibleOn}>
                    Visible
                </CalciteRadioGroupItem>
                <CalciteRadioGroupItem value="off" {...visibleOff}>
                    Hidden
                </CalciteRadioGroupItem>
            </CalciteRadioGroup>
        </CalciteLabel>
        <CalciteLabel>
            Opacity:
            <CalciteSlider
                labelTicks ticks="25" max="100" value={layerItem.opacity * 100}
                onCalciteSliderChange={evt => layerItem.opacity = evt.target.value / 100
                }></CalciteSlider>
        </CalciteLabel>
        <CalciteButton
            style={{ marginBottom: '1em' }}
            appearance="outline"
            color="red"
            width={"full"}
            onClick={() => {handleRemove(layerItem)}}
        >
            Remove Layer From Map
            </CalciteButton>
    </CalciteBlock>
  )
}

export default LayerListBlock