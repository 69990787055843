import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://mapsdep.nj.gov/arcgis/rest/services/Applications/MS4_Map/MapServer/5";

const portalId = "c8a10787296e4ed59437028a3d0837f0";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Outfall",
  popupTemplate: {
    title: "{FACILITY_NAME}",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
      {
        type: "fields",
        fieldInfos: [
          { fieldName: "ROADNAME", label: "Road Name" },
          { fieldName: "OUTFALLTYPE", label: "Outfall Type" },
          { fieldName: "PIPEMATERIAL", label: "Pipe Material" },
          { fieldName: "PIPESHAPE", label: "Pipe Shape" },
          { fieldName: "PIPEHEIGHT", label: "Pipe Height" },
          { fieldName: "PIPEWIDTH", label: "Pipe Width" },
          { fieldName: "HEADWALLSTRUCTURE", label: "Headwall Structure" },
          { fieldName: "BMPSTRUCTURE", label: "BMP Structure" },
          { fieldName: "CONTRIBUTINGDA", label: "Contributing" },
          { fieldName: "TYPEAREARECEIVING", label: "Type of Area Receiving" },
          { fieldName: "COLLECTIONMETHOD", label: "Collection Method" },
          { fieldName: "COMMENTS", label: "Comments" },
        ],
      },
    ],
  },
  layerDescription: "The purpose of this data is to is to help NJPDES MS4 permittees develop a working inventory of stormwater facilities. The EPA has suggested that MS4 permittees possess an inventory as a means of ensuring adequate long term operation and maintenance of their stormwater facilities. While proper operation and maintenance of stormwater facilities has been required of permittees in past MS4 NJPDES permits, it has been found that many stormwater facilities throughout the state are not properly functioning due to inadequate operation and maintenance. Compliance with the permit requirement to collect this data will provide municipalities, public complexes, and highway agencies with powerful tools that support stormwater facility operation and maintenance as well as support overall asset management.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const outfall = {layerId: 'outfall', type: 'layer', item: layer, meta: layer}
