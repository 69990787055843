import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url =  "https://coast.noaa.gov/arcgis/rest/services/MarineCadastre/NavigationAndMarineTransportation/MapServer/0";

const portalId = "4fa3f08188804678b90b9ae4d8ce3720";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "USCG Aids to Navigation - Zoom In",
  popupTemplate: {
    title: "{name}",
    content: `<b>Structure:</b> {structure}<hr/> <a href=${url}>More Info</a>`,
  },
  layerDescription: "USCG Aids to Navigation",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const uscgNav = {layerId: 'uscgNav', type: 'layer', item: layer, meta: layer}
  
