import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Delaware_Bay_Designation_Lines/FeatureServer";

const portalId = "2f43e926a4f44fd08f1e3fb3e80d6fbc";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "Delaware Bay Designation Lines",
  popupTemplate: {
    title: "{NAME}",
    content: [
      {type: 'text', text: "{COMMENTS}"},
      {type: 'text', text: `<a href='${url}'>More Info</a>`},
    ]
  },
  layerDescription: "This layer depicts the following four designation lines: the Clam Line, the Crab Dredge Line, the Southwest Line, and the Freshwater line. A brief description of each line's purpose and where they are described in the Administrative Code or State Statute is given below:Clam Line: This line is referred to in New Jersey Statute Title 50 3:16 along with the Brandywine-Dennis Creek Line. The Clam Line designates the up-bay/northeastern portions of Areas - 1, 2 and 3. Furthermore, the Brandywine-Dennis Creek Line designates the down-bay/southwestern portions of Areas - 1, 2 and 3.Crab Dredge Line: This line is described in N.J.A.C 7:25 - 14.7. This line depicts the most up-bay region crab dredges can be legally used. North of this line are shellfish leases that prohibits the act of commercial crab dredging. Southwest Line: This line is referenced in N.J.A.C 7:25 - 18.6 relating to gill nets and in New Jersey Statute Title 50 1-23, which states no ground shall be leased above [up-bay/northeast] of this line. Freshwater Line: This line is where salinity in the Delaware River reaches 0.0 on average. It is located at or just south of the Commodore Barry Bridge. Further information of this line can be found in the Freshwater Fishing Digest Magazine. The layer will be review annually to inspect for changes in the governing rules and regulation that define the designation lines shown on this layer.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const delLines = {layerId: 'delLines', type: 'layer', item: layer, meta: layer}

