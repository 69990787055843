import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://mapsdep.nj.gov/arcgis/rest/services/Features/Environmental_admin/MapServer/40";

let portalId = "1b20e9eb87eb48f28e385f40d8e6db5d";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  layerId: 40,
  title: "Shellfish Lease Sections in New Jersey",
  popupTemplate: {
    title: "{REGION_ID} {SEC_LABEL}",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a> <br/><b>Region:</b> {REGION}<br/>    <b>Section:</b> {SECTION}<br/> `
      }
    ],
  },
  visible: true,
  layerDescription: "The data layer provides the location of shellfish lease sections along the Atlantic coast of New Jersey and in the Delaware Bay. Leases are granted by the New Jersey Shellfisheries Council, upon approval from the Commissioner of the NJDEP. This authority of the Council is to Grant Leases is by Title 50, NJSA 50:1-24 and codified in the Leasing of Atlantic Coast Bottom for Aquaculture regulations at NJAC 7:25-24.",
  mapService: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
  authoritiveSource: `https://www.nj.gov/dep/gis/`
});

export const shellfishSections = {layerId: 'shellfishSections', type: 'layer', item: layer, meta: layer}



