import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import getMapSvcOps from "../../../../../tools/getMapSvcOps";

// !! the portal item for the Estuarine Inventory doesn't return the sub-layer items

//** setting this up to loop through the url layer ids for the layers and generate the items */

const url = 'https://services1.arcgis.com/QWdNfRs7lkPq4g4Q/ArcGIS/rest/services/Estuarine_Inventory_of_New_Jersey/FeatureServer/'

const urlIdArray = [0, 1, 2, 3, 4, 5,6,108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125]

const clamGroup = {
    id: 'clam',
    title: "Hard Clam",
    type: 'group',
    items: [],
};

urlIdArray.map(async (id) => {
    let item = new FeatureLayer({
        url: `${url}${id}`,
        visible: true,
        mapService: `${url}${id}`,
        popupEnabled: true,
        
    })
    getMapSvcOps(item).then((d) => {
        clamGroup.items.push(
            {
                key: `sav${id}`,
                id: `sav${id}`,
                layerId: `sav${id}`,
                type: 'layer',
                item,
                meta: {
                    title: d.title,
                    layerDescription: d.description,
                    mapService: `${url}${id}`,
                    authoritiveSource: `https://dep.nj.gov/njfw/`
                }
            }
        )
    })
    
})

export {clamGroup}
