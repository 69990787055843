import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/NJDOT_Confined_Disposal_Facilities/FeatureServer";

const portalId = "ad22b1ae62e1497c9d089b1dbb2fc30f";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "NJDOT Confined Disposal Facilities",
  layerDescription: "Description for this layer is under construction.",
  popupTemplate: {
    title: "{Name_1}",
    content: [
      {
        type: "text",
        text: `<a href=${url}>More Info</a>`,
      },
    ],
  },
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const njdotCdf = {layerId: 'njdotCdf', type: 'layer', item: layer, meta: layer}
