import { shellfishExclusion } from "../existingBeds/portalShellfishExclusion";
// import { atlTtl50 } from "./portalAtlanticTitle50";
// import { brigantine } from "./portalBrigantine";
// import { capeHorn } from "./portalCapeHorn";
import { delLines } from "./portalDelLines";
import { dryBay } from "./portalDryBay";
// import { goosebar } from "./portalGoosebar";
// import { gravelingBeds } from "./portalGravelingBeds";
// import { greatEgg } from "./portalGreatEgg";
// import { greatSound } from "./portalGreatSound";
// import { lakesBay } from "./portalLakesBay";
import { mullicaRiver } from "./portalMullicaRiver50";
// import { redKnot } from "./portalRedKnot";
// import { sunflower } from "./portalSunflower";

export const regLayers = {
  id: 'reg',
  type: 'group',
  title: "Regulatory Layers",
  items: [
    // atlTtl50,
    // brigantine,
    // capeHorn,
    delLines,
    dryBay,
    // goosebar,
    // gravelingBeds,
    // greatEgg,
    // greatSound,
    // lakesBay,
    mullicaRiver,
    // redKnot,
    shellfishExclusion,
    // sunflower,
  ],
};
