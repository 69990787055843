import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const FloodDesc = () => (
  <>
  <p>To learn more about Total Water Levels, visit <a href='https://www.njfloodmapper.org' target='_blank' rel='noreferrer'>njfloodmapper</a> and click the Total Water Level tutorial.  This dataset was created as part of an online mapping viewer depicting potential coastal flooding and its associated impacts on New Jersey's coastal areas. The purpose of the mapping viewer is to provide coastal managers and scientists with a preliminary look at coastal flooding impacts. The viewer is a screening-level tool that uses nationally consistent data sets and analyses. Data and maps provided can be used at several scales to help gauge trends and prioritize actions for different scenarios.</p>
  <p>
The purpose of this dataset is to show current Mean Higher High Water (MHHW) for the area. Tiles have been cached down to Level ID 16 (1:9,027).</p>
<p>
This dataset illustrates the scale of potential flooding, not the exact location, and does not account for erosion, subsidence, or future construction. Inundation is shown as it would appear during the highest high tides (excludes wind driven tides). The dataset should be used only as a screening-level tool for management decisions. As with all remotely sensed data, all features should be verified with a site visit. The dataset is provided "as is," without warranty to its performance, merchantable state, or fitness for any particular purpose. The entire risk associated with the results and performance of this dataset is assumed by the user. This dataset should be used strictly as a planning reference and not for navigation, permitting, or other legal purposes.</p>
</>
)

const url = "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/"



// Total Water Levels - importing all MHHW layers and then grouping them
let twl00 = {
layerId: 'twl00',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_00ft/MapServer",
  title: "Total Water Level MHHW_00ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_00ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl01 = {
layerId: 'twl01',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_01ft/MapServer",
  title: "Total Water Level MHHW_01ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_01ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl02 = {
layerId: 'twl02',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_02ft/MapServer",
  title: "Total Water Level MHHW_02ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_02ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl03 = {
layerId: 'twl03',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_03ft/MapServer",
  title: "Total Water Level MHHW_03ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_03ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl04 = {
layerId: 'twl04',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_04ft/MapServer",
  title: "Total Water Level MHHW_04ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_04ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl05 = {
layerId: 'twl05',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_05ft/MapServer",
  title: "Total Water Level MHHW_05ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_05ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl06 = {
layerId: 'twl06',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_06ft/MapServer",
  title: "Total Water Level MHHW_06ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_06ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl07 = {
layerId: 'twl07',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_07ft/MapServer",
  title: "Total Water Level MHHW_07ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_07ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl08 = {
layerId: 'twl08',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_08ft/MapServer",
  title: "Total Water Level MHHW_08ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_08ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl09 = {
layerId: 'twl09',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_09ft/MapServer",
  title: "Total Water Level MHHW_09ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_09ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl10 = {
layerId: 'twl10',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_10ft/MapServer",
  title: "Total Water Level MHHW_10ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_10ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl11 = {
layerId: 'twl11',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_11ft/MapServer",
  title: "Total Water Level MHHW_11ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_11ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl12 = {
layerId: 'twl12',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_12ft/MapServer",
  title: "Total Water Level MHHW_12ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_12ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl13 = {
layerId: 'twl13',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_13ft/MapServer",
  title: "Total Water Level MHHW_13ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_13ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl14 = {
layerId: 'twl14',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_14ft/MapServer",
  title: "Total Water Level MHHW_14ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_14ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl15 = {
layerId: 'twl15',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_15ft/MapServer",
  title: "Total Water Level MHHW_15ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_15ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl16 = {
layerId: 'twl16',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_16ft/MapServer",
  title: "Total Water Level MHHW_16ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_16ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl17 = {
layerId: 'twl17',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_17ft/MapServer",
  title: "Total Water Level MHHW_17ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_17ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl18 = {
layerId: 'twl18',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_18ft/MapServer",
  title: "Total Water Level MHHW_18ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_18ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl19 = {
layerId: 'twl19',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_19ft/MapServer",
  title: "Total Water Level MHHW_19ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_19ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 
let twl20 = {
layerId: 'twl20',
type: 'layer',
  item: new MapImageLayer({
  url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/CoastalFlooding/MHHW_20ft/MapServer",
  title: "Total Water Level MHHW_20ft",
  layerDescription: <FloodDesc/>,
  visible: true,
}),
meta: {
  title: "Total Water Level MHHW_20ft",
  layerDescription: <FloodDesc/>,
  mapService: url,
  authoritiveSource: 'https://crssa.rutgers.edu/coastal_marine.html',
}
} 

export const twlGroup = {
  id: 'twl',
  title: "Total Water Levels",
  type: 'group',
  items: [
    twl00,
    twl01,
    twl02,
    twl03,
    twl04,
    twl05,
    twl06,
    twl07,
    twl08,
    twl09,
    twl10,
    twl11,
    twl12,
    twl13,
    twl14,
    twl15,
    twl16,
    twl17,
    twl18,
    twl19,
    twl20,
  ],
  
};
