import React from 'react';
// import { CalciteButton } from '@esri/calcite-components-react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { CreateUrlFromUrlOptions, generateInternalLink, GenerateUrlOptions } from '../../../Logic/UrlOptionsLogic';


const Thanks = () => {

  // const navigate = useNavigate()

  // const {arcgisMap} = useSelector(state => state)

  // const handleShowModal = () => {
  //   window.localStorage.setItem('intro-expires', Date.now() - 3600 );
  //   let urlOptions = GenerateUrlOptions(arcgisMap)
  //   let tempUrl = generateInternalLink(urlOptions)
  //   navigate(tempUrl)

  // }

  return (
    <div style={{margin: '1rem'}}>
      <p>
      This tool is the result of work sponsored by the New Jersey Sea Grant Consortium (NJSGC) with funds from the National Oceanic and Atmospheric Administration (NOAA) Office of Sea Grant, U.S. Department of Commerce, under NOAA grant number NA19OAR4170325 and the NJSGC.</p>
      <p>
       Additional support was provided by The Pew Charitable Trusts (Contract #33709) and USDA NIFA Hatch project NJ32114 via the New Jersey Agricultural Experiment Station. An independent Technical Advisory Group and and a Project Working Group comprised of stakeholders from across NJ provided their expertise in database management, GIS technology, the identification of data resources and the perspectives of the diverse stakeholder community.
      </p>
      {/* <CalciteButton appearance='outline' onClick={handleShowModal}>Show Welcome Window Again</CalciteButton> */}
    </div>
  )
}

export default Thanks