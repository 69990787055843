import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

let url = "https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/Delaware_Bay_Areas_2_and_3/FeatureServer";

let portalId = "01e6d6b76f354ac7a1fb44d83e3edcd7";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  title: "Delaware Bay Areas 2 and 3",
  popupTemplate: {
    title: "{Name}",
  },
  visible: true,
  layerDescription: "This layer depicts the location of Areas 2 and 3 found in the Delaware Bay. The areas' geographic coordinates and the areas' rules and regulations can be found in New Jersey Statute Title 50 starting at chapter and section 3:16.10 to 3:16.22New Jersey Statute Title 50 3:16.10 gives Area 2's coordinate dataNew Jersey Statute Title 50 3:16.11 omittedNew Jersey Statute Title 50 3:16.12 gives Area 3's coordinate dataNew Jersey Statute Title 50 3:16.13 sets the groundwork for the legal harvest of shellfish in Areas 2 and 3New Jersey Statute Title 50 3:16.14 establishes the need for a special license to harvest from Areas 2 & 3, and this section establishes rules pertaining to the special license.New Jersey Statute Title 50 3:16.15 establishes how to apply for an Area 2 or Area 3 license New Jersey Statute Title 50 3:16.16 sets the fees associated with the Areas licenses.New Jersey Statute Title 50 3:16.17 allows the Department to revoke a license if a false oath is madeNew Jersey Statute Title 50 3:16.18 sets the minimum size for harvest in Areas 2 & 3 New Jersey Statute Title 50 3:16.19 sets the harvest season - October to April - no harvest on Saturdays or SundaysNew Jersey Statute Title 50 3:16.20 establishes the guidelines for dredge equipment that can be utilized in Areas 2 & 3New Jersey Statute Title 50 3:16.21 gives power to the Shellfish Council, subject to the approval of the Commissioner, to open and close Areas 1, 2, & 3 or portions of as part of recourse management measures New Jersey Statute Title 50 3:16.22 creates the need to file and store licence applications for Areas 1, 2, & 3The layer will be reviewed annually to inspect for changes in the governing rules and regulation that define the designation lines shown on this layer. It was last inspected and updated on 12/23/2020.",
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const delAreas = {layerId: 'delAreas', type: 'layer', item: layer, meta: layer}
