import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://mapsdep.nj.gov/arcgis/rest/services/Features/Environmental_mon_water/MapServer/20";

const portalId = "69cf3011f25d46d48b60276226ea9d0f";

export const layer = new FeatureLayer({
  portalItem: {
    id: portalId,
  },
  visible: true,
  title: "NSSP Stations - Zoom to View",
  popupEnabled: true,
  layerDescription: "The data represents monitoring stations both active and historical that are sampled by the Bureau of Marine Water Monitoring (BMWM) for the National Shellfish Sanitation Program (NSSP). A scheduling system indicating which NSSP stations and the number of visits per year to each station are established at the beginning of each year at BMWM. Field staff collect water samples at these stations and drop the samples off for Fecal Coliform bacteria lab analysis at the Leeds Point facility. The results from these samples dictate NSSP status classifications of Approved, Prohibited, Special Restricted, Seasonal (Jan – Apr), or Seasonal (Jan – Nov) for NJ’s marine waters. Data results for the NSSP Program that were analyzed by Leeds Point are uploaded into NJDEP’s Water Quality Data Exchange (WQDE) system. During the upload process, numerous QA procedures are run to verify data integrity. Once data has passed the quality control checks, the data resides in NJDEP’s COMPASS database. It is from this database that the stations are created and the raw data results related to the stations originate. This is accomplished through several Builder routines. Only data (stations and lab results) that are loaded into COMPASS will be reflected in this dataset.", 
  mapService: url,
  authoritiveSource: `https://rutgers.maps.arcgis.com/home/item.html?id=${portalId}`,
});

export const nsspStations = {layerId: 'nsspStations', type: 'layer', item: layer, meta: layer}
